import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
import ANDR from "../Assest/Images/ANDORID1.png";
// import IOS from "../Assest/Images/IOS1.png";
import Slide1 from "../Assest/Images/DeliverX/1.jpg";
import Slide2 from "../Assest/Images/DeliverX/2.jpg";
import Slide3 from "../Assest/Images/DeliverX/3.jpg";
import Slide4 from "../Assest/Images/DeliverX/4.jpg";
import Slide5 from "../Assest/Images/DeliverX/5.jpg";
import Slide6 from "../Assest/Images/DeliverX/6.jpg";
import Slide7 from "../Assest/Images/DeliverX/7.jpg";
// import { Link } from "react-router-dom";

const slide3 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link
          target="_blank"
          to={"/on-demand-pickup-and-delivery-app-development-services-company"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-pickup-and-delivery-app-development-services" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-pickup-and-delivery-app-development-services" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-pickup-and-delivery-app-development-services" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-pickup-and-delivery-app-development-services" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-pickup-and-delivery-app-development-services" src={Slide5} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-pickup-and-delivery-app-development-services" src={Slide6} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-pickup-and-delivery-app-development-services" src={Slide7} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                8<span>Months</span>
              </h4>
              <h3>Deliver X</h3>
              <h5>Flutter</h5>
              <h1>Built with technology look up</h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Mobile Application:</h4>
                  <ul>
                    <li>Dart Language</li>
                    <li>Bloc Codebase Structure</li>
                    <li>Flutter Framework</li>
                    <li>Google Place APIs and Google Map Integrations</li>
                    <li>Firebase Cloud Messaging Notifications</li>
                  </ul>
                </div>

                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>PHP with Laravel Framework</li>
                    <li>PHPMYAdmin + MySQL</li>
                  </ul>
                </div>

                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span>
                      <a href="https://play.google.com/store/apps/details?id=com.deliveryx.customer&hl=en-IN">
                        <img src={ANDR} alt="Android" />
                      </a>
                    </span>
                  </div>
                </div>
                <div></div>
                <div className="col_g">
                  {/* <Link
                    to={
                      "/on-demand-pickup-and-delivery-app-development-services-company"
                    }
                    className="showMore mt-0"
                  
                  >
                    Read the full case study
                  </Link> */}
                  <a
                  href="/on-demand-pickup-and-delivery-app-development-services-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide3;
