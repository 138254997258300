import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";


import Front1 from "../Assest/Images/front1.png";
import Front2 from "../Assest/Images/front2.png";
import Front3 from "../Assest/Images/front3.png";
import Front4 from "../Assest/Images/front4.png";
import Front5 from "../Assest/Images/front5.png";
import Front6 from "../Assest/Images/front6.png";
import Backend1 from "../Assest/Images/backend1.png";
import Backend2 from "../Assest/Images/backend2.png";
import Backend3 from "../Assest/Images/backend3.png";
import Backend4 from "../Assest/Images/backend4.png";
import Backend5 from "../Assest/Images/backend5.png";
import Backend6 from "../Assest/Images/backend6.png";
import Backend7 from "../Assest/Images/backend7.png";
import Backend8 from "../Assest/Images/backend8.png";

const Stack = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    autoplaySpeed: 1000,
    speed: 2000,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div>
      
      <Slider className="stack_slider"
      {...settings}
        asNavFor={nav1}
        ref={slider2}
        // slidesToShow={8}
        swipeToSlide={true}
        focusOnSelect={true}
      >
        <div className="thumb">
        <img src={Front1} alt="thumb1"/>
        </div>
        <div className="thumb">
        <img src={Front2} alt="thumb2"/>
        </div>
        <div className="thumb">
        <img src={Front3} alt="thumb3"/>
        </div>
        <div className="thumb">
        <img src={Front4} alt="thumb4"/>
        </div>
        <div className="thumb">
        <img src={Front5} alt="thumb5"/>
        </div>
        <div className="thumb">
        <img src={Front6} alt="thumb6"/>
        </div>
        <div className="thumb">
        <img src={Backend1} alt="thumb7"/>
        </div>
        <div className="thumb">
        <img src={Backend2} alt="thumb8"/>
        </div>
        <div className="thumb">
        <img src={Backend3} alt="thumb9"/>
        </div>
        <div className="thumb">
        <img src={Backend4} alt="thumb10"/>
        </div>
        <div className="thumb">
        <img src={Backend5} alt="Thumb"/>
        </div>
        <div className="thumb">
        <img src={Backend6} alt="Thumb"/>
        </div>
        <div className="thumb">
        <img src={Backend7} alt="Thumb"/>
        </div>
        <div className="thumb">
        <img src={Backend8} alt="Thumb"/>
        </div>
      </Slider>
    </div>
  );

};

export default Stack;
