import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 

import "./home.css";
import DXimg from "../Assest/Images/dx_banner.png"
import ButtonContact from '../Component/ButtonContact';
import Ideaimg from "../Assest/Images/project-idea-Dx.png";
import Solution from "../Assest/Images/solution-dx.png";
import Scooter from "../Assest/Images/delivery-scooterdx.png";
import CustomerDisplay from "../Assest/Images/customer-app-dx.png";
import DriverDisplay from "../Assest/Images/driver-app-dx.png";
import DXIcon from "../Assest/Images/Dx-icon.png";
import FlutterIcon from "../Assest/Images/flutter-icon.png";
import googlePlay from "../Assest/Images/googleplay.png";
import MernIcon from "../Assest/Images/mern-icon.png";



import { useEffect } from "react";
import { Container } from "react-bootstrap";




export function Deliverx(props) {
  useEffect(() => {
    AOS.init();
  }, [])

  React.useEffect(() => {
    document.title = 'On-demand pickup & delivery app development services company';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Create your own pickup & delivery app similar to the DeliverX app, developed by Immanent Solutions. We can assist you in developing clones of Instacart, Postmates, TaskRabbit, and Caviar (acquired by DoorDash).');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Pickup & Delivery App Development Services, Instacart clone, Postmates Clone, TaskRabbit Clone, Caviar (acquired by DoorDash) Clone');
  
    // Check if canonical link already exists
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://immanentmobile.dev/on-demand-pickup-and-delivery-app-development-services-company');
  
  
  }, []);


  return (  
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner">
    <div className="innerpagebanner_img">
        <img
        // className="up-down"
        alt="DX" src={DXimg} className="mx-w-100" />
      </div>
      <div className="banner_text orange-banner-bg m-s0 bg-grey px-1"  data-aos="zoom-out" data-aos-delay="400">
        <div>
        <img src={DXIcon} alt="Dx icon" className="img-fluid" />
       
        
        </div>
        <div className="app-store-icons">
                  <a href="https://play.google.com/store/apps/details?id=com.deliveryx.customer&hl=en-IN" target="_blank"><img src={googlePlay} alt="Deliverx"/></a>
                
              </div>
      </div>
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-red-light" 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading text-brown sentensecase">What is deliverX?
</h1>
      <p className="para-grey">DriveX is a mobile application designed to revolutionize the way small 
items are delivered within cities. It provides a seamless platform for users 
to request pick-up and delivery services, while ensuring realtime tracking 
and efficient management for delivery drivers.
</p>
    </Container>
  </div>
</section>



<section className="project-idea" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex  project-pad">
        <div className="col-md-6 proj-block-big red"><img src={Ideaimg} alt="Project Idea" className=" updx"/> </div>
        <div className="col-md-6 pt-5 mt-6 pt-sm-0">
          <h1 className="subpage-heading heading-box-end head-box-red"> Project Idea</h1>
           <p className="para-grey ">
           The core idea behind DeliverX is to 
revolutionize the way small-item deliveries 
are conducted in cities. By creating a user-
friendly and efficient platform, the applicati
on connects users in need of delivery 
services with drivers available to fulfill those
requests. The emphasis is on providing a 
convenient, real-time, and reliable solution 
for urban delivery needs
           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center ">
        <div className="col-lg-12 pt-5 text-center">
         <h1 className="subpage-heading heading-box-end head-box-red ">Challenges </h1>
          <p className="para-grey text-center">
          DeliverX faced pivotal technical challenges, notably in real-time location tracking for 
precise delivery estimates and route optimization. Dynamic delivery management 
was also crucial, requiring real-time handling of pick-up requests to ensure seamless 
operations and minimize delays.
          </p>
        </div>
        
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-6 ">
       <h1 className="subpage-heading heading-box-end head-box-red">Solutions</h1>
        <p className="para-grey">
        The DeliverX project overcame challenges by 
integrating the Google Maps API for precise 
driver tracking, enabling users to monitor real-time 
delivery progress. A well-designed system for pick-
up requests was implemented, ensuring drivers 
receive timely notifications. The project also 
accomplished a user-friendly interface, enhancing 
efficiency for both users and delivery drivers.

          </p>
        </div>
        <div  className="col-lg-6 text-end">
          <img src={Solution} alt="Solution" className="img-fluid"/>
        </div>
      </div>
    </Container>
  </div>
</section>



<section className="display-app" id="Features">
      <Container>
        <div className="row mt-5"> 
           
        <div className="col-lg-6 ps-3 order-md-first order-last">
              <img src={DriverDisplay}  alt="Driver Display" className="img-fluid margin-top-50"/>
            </div>
            <div className="col-lg-6">
              <div className="heading-outside-bg outside-red">
             <h1 className="subpage-heading heading-inside-bg ">Driver App</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
                <li>Order Management</li>
                <li>Time Management</li>
                <li>Earnings Report</li>
                <li>Ratings & Reviews</li>
                <li>Advanced Optimized Routing</li>
              </ul>
             </div>
            </div>
           
        </div>
        <div className="row project-pad"> 
           
           
            <div className="col-lg-6">
              <div className="heading-outside-bg heading-outside-bg2 outside-red">
             <h1 className="subpage-heading heading-inside-bg ">Customer App</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
                <li>Order Placement</li>
                <li>Cart Management</li>
                <li>Online Payment</li>
                <li>Real-Time Order Tracking</li>
                <li>Ratings & Reviews</li>
                <li>Locate Restaurants Nearby</li>
              </ul>
             </div>
            </div>
            <div className="col-lg-6 ps-3 text-end">
              <img src={CustomerDisplay}  alt="Customer Display" className="img-fluid margin-top-50"/>
            </div>
        </div>
      </Container>
</section>


<section className="app-services-block" id="Services">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center project-pad">
      <div className="col-lg-12 text-center">
       <h1 className="subpage-heading heading-box-end head-box-red ">Services we provide &nbsp;</h1>
        <div className="inner_contac ">
        <div className="inner_contact">
          <div className="inner_subappservice ">
            <div className="appservice_grd bg-red-light">
              <div className="grd_txt">
                <p>Concept <br />Brainstorming</p>
              </div>
            </div>
            <div className="appservice_grd bg-red">
              <div className="grd_txt">
              <p>Wireframing and 
 <br />Architectural Design</p>
              </div>
            </div>
            <div className="appservice_grd bg-red-light">
              <div className="grd_txt">
              <p> UI/UX 
 <br />graphics</p>
              </div>
            </div>
            <div className="appservice_grd bg-red">
              <div className="grd_txt">
              <p>Development </p>
              </div>
            </div>
            <div className="appservice_grd bg-red-light">
              <div className="grd_txt">
              <p>App store & Play Store 
 <br />publication assistance</p>
              </div>
            </div>
            <div className="appservice_grd bg-red">
              <div className="grd_txt">
              <p>Testing and 
            <br />Review</p>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="tech-stack-block bg-red-light" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-red">Tech Stack</h1>
          <div className="tech-stack-icons">
            <img src={FlutterIcon} alt="icone" />
        
            <img src={MernIcon} alt="icon" />
          </div>
          </div>
         </div>
      </div>
      
    </Container>
  {/* <div className="rating-app">
                
                <div className="hr-border-label border-color-red"> 
                <Container>
                  <div className="row">
                      <div className="col-lg-12">
                        <div className="labels-rating">
                            <div className="rating-1">
                                <img src={Hex} alt="hex" />
                            </div>
                            <div className="rating-2">
                                <img src={Hex} alt="hex" />
                            </div>
                          <div className="rating-1-content">
                                <div className="circle-rate border-color-red">
                                    4.6
                                </div>
                                <div className="label-text">
                                  Rating
                                </div>
                          </div>
                          <div className="rating-2-content">
                              
                                <div className="label-text">
                                  Downloads
                                </div>
                                <div className="circle-rate-2 bg-red">
                                    5M+
                                </div>
                          </div>
                        </div>
                       
                      </div>
                  </div>
                </Container>                 
                </div>
  </div> */}
  </div>
  
</section>
<section className="delivery-dx bg-red mb-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-7"> 
          <div className="delivery-text">
          <h3>Deliver anything, anywhere, 
              anytime with your own App!</h3>
              <div className="mt-4">
              <ButtonContact />
              </div>
          </div>
            
          </div>
          <div className="col-lg-5 text-end">
              <img src={Scooter} alt="Deliver Anything" className="img-fluid margin-top-50"/>
          </div>
      </div>
  </Container>
</section>



      
      <Footer></Footer>
  
    </main>
  )
}
