import React, { useState } from "react";
// import { bounce } from 'react-animations';
import AOS from "aos";
import "aos/dist/aos.css";
import { Header } from "../Component/Headerwhite";
import { Footer } from "../Component/Footer";

import "./home.css";
import Bannerimg from "../Assest/Images/askavet_banner.png";

import Ideaimg from "../Assest/Images/project-idea-Dx.png";
import Solution from "../Assest/Images/solution-askavet.png";

import Logo from "../Assest/Images/askvet-logo.png";
import ReactIcon from "../Assest/Images/reactnativeicon.png";
// import MongoIcon from "../Assest/Images/mongodb-icon.png";
import MernIcon from "../Assest/Images/mern-icon.png";
// import NodeIcon from "../Assest/Images/nodejs-icon.png";
import FirebaseIcon from "../Assest/Images/firebase.png";
import HashIcon from "../Assest/Images/hash-icon.png";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Logoicon from "../Assest/Images/askavetlogo.png";
import Display1 from "../Assest/Images/display1askavet.png";
import SuperAdmin from "../Assest/Images/superadmin-d-ask.png";
import Petwebapp from "../Assest/Images/petwoner-wapp.png";
import googlePlay from "../Assest/Images/googleplay.png";
import appleStore from "../Assest/Images/appstore.png";
import ButtonContact from "../Component/ButtonContact";
import { Link } from "react-router-dom";


export function Askavet(props) {
  

  useEffect(() => {
    AOS.init();
  }, []);

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleShowLess = () => {
    setShowMore(false);
  };

  const [showMore2, setShowMore2] = useState(false);

  const handleShowMore2 = () => {
    setShowMore2(true);
  };

  const handleShowLess2 = () => {
    setShowMore2(false);
  };

  React.useEffect(() => {
    document.title =
      "Pet Care & Veterinary Consultation App Development Company";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        'Looking for a pet care and veterinary app developer? Contact Immanent Solutions for tailored solutions. Explore the "Ask A Vet" app.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        "content",
        "Pet Healthcare App, Veterinary Care App Development, Pet Health and Wellness App, Veterinary Consultation App, Mobile App for Online Vet Consultations and Prescriptions"
      );

    // Check if canonical link already exists
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://immanentmobile.dev/petcare-and-veterinary-consultation-app-development-company');

  }, []);

  return (
    <main>
      <Header name="Immanent Solutions"></Header>
      <section className="banner-sub" id="home">
        <Container>
          <div className="innerpage_banner justify-content-between">
            <div className="innerpagebanner_img all-take-w col-lg-8 pt-4 text-end">
              <img
                // className="up-down"
                alt="banner"
                src={Bannerimg}
                className="mx-w-100 shadow-lg"
              />
            </div>
            <div
              className="banner_text orange-banner-bg ask-vet m-s0 bg-lt-sky text-white pe-2 col-lg-4"
              data-aos="zoom-out"
              data-aos-delay="400"            >
              <div>
                <img src={Logo} alt="Logo" className="mx-w-100" />
              </div>
              <div className="app-store-icons">
                  <a href="https://apps.apple.com/in/app/ask-a-vet/id6451296634" target="_blank"><img src={appleStore} alt="pet-app"/></a>
                  <a href="https://apps.apple.com/in/app/vetdoctor/id6451299868" target="_blank"><img src={appleStore} alt="vet-store"/></a>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section
        className="what-is-section bg-ltpnkred "
        // style={{'display' : 'none'}}
      >
        <div
          className="inner_Stack"
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <Container>
            <h1 className="subpage-heading blue-text sentensecase">What is Ask A Vet?</h1>
            <p className="para-grey ">
              Ask A Vet is an inclusive mobile application that serves as a
              seamless bridge between pet owners and veterinarians. This
              innovative platform empowers pet owners to seek expert advice on
              various aspects of their pets' health, behavior, and general care.
              Simultaneously, it provides veterinarians with a tool to
              efficiently manage their practice, connect with pet owners, and
              deliver personalized consultations.
            </p>
          </Container>
        </div>
      </section>

      <section className="project-idea" id="Project">
        <div
          // data-aos="fade-up"
          // data-aos-offset="100"
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="100"
        >
          <Container>
            <div className="zig-grid2 align-items-center project-pad">
              <div className="proj-block-small yellowlt">
                <img src={Ideaimg} alt="Project Idea" className="img-fluid" />{" "}
              </div>
              <div className="">
                <h1 className="subpage-heading heading-box-end head-box-redorangelt">
                  {" "}
                  Project Idea
                </h1>
                <p className="para-grey">
                  The Ask A Vet app addresses the growing need for accessible
                  and reliable veterinary advice, catering to the evolving
                  demands of pet care in today's digital age. By connecting
                  veterinarians and pet owners in real-time, the app aims to
                  enhance the quality of care, improve communication, and foster
                  a stronger bond between professionals and pet enthusiasts. The
                  inclusion of premium features ensures sustainability and
                  continued development, providing a comprehensive solution for
                  the evolving needs of the veterinary community and pet owners
                  alike.
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-center project-pad">
              <div className="col-lg-12 pt-5 text-center">
                <h1 className="subpage-heading heading-box-end head-box-sgreenlt ">
                  Challenges Faced &nbsp;
                </h1>
                <p className="para-grey text-center">
                  Designing an algorithm to intelligently match pet owner
                  queries with suitable veterinarians, factoring in expertise,
                  pet profiles, and recent activities, proved challenging.
                  Implementing a seamless real-time chat and media-sharing for
                  effective communication, along with addressing complexities in
                  In-App Purchases, such as diverse payment methods, secure
                  transactions, subscription plans, and user authentication,
                  added significant layers of complexity to the development
                  process.
                </p>
              </div>
            </div>
            <div className="zig-grid  project-pad">
              <div className="align-self-end">
                <h1 className="subpage-heading heading-box-end head-box-redorangelt">
                  Solutions
                </h1>
                <p className="para-grey">
                  We successfully implemented an advanced Question Matching
                  Algorithm, intelligently pairing pet owner queries with
                  suitable veterinarians. Additionally, we integrated Firebase
                  Real-Time Chat for seamless communication while meticulous
                  testing and Redux optimization guarantee a flawless user
                  experience, including efficient In-App Purchases and
                  responsive interface.
                </p>
              </div>
              <div className="align-self-end text-end">
                <img src={Solution} alt="Solution" className="solutions-img max-height-70" />
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="core-features lt-paleyellow" id="Features">
        <div className="core-features-inner1">
          <Container>
            <div className="row">
              <div className="col-lg-12 text-center">
                <h1 className="subpage-heading text-blue">
                  Main features
                  <br />
                </h1>
              </div>
            </div>
            <div className="row app-d">
              <div className="col-md-12">
                <div className="zigzag">
                  <div className="">
                    <h1 className="subpage-heading heading-box-end head-box-redlt">
                      Pet Owner <br />
                      Mobile App&nbsp;
                    </h1>
                    <p className="para-grey">
                      <ul className="circle-list blue-circle-list f-38">
                        <li>Get Instant Answers from Specialist </li>
                        <li>Vets for Your Pets</li>
                        <li>
                          In-App Purchases for Questions and Monthly
                          Subscriptions
                        </li>
                        <li>Create Questions with Attachments for Vets</li>
                        <li> Telehealth Consultation via Video Call</li>
                        <li>Manage Pets and Farm Animals</li>
                        {showMore && (
                          <>
                            <li>Real-Time Chat</li>
                            <li>Vet Allocation Algorithm for Quick Answers</li>
                            <li>
                              {" "}
                              Manage Pet/Farm Animal Records (Documents,
                              Medications, Food, Events, etc.)
                            </li>
                            <li>
                              {" "}
                              Create Reminders for Pet Events (Food, Medication)
                            </li>
                            <li> Real-Time Push Notifications</li>
                            <li>
                              {" "}
                              Graphs and Analytics Reports for Pet Health and
                              Medications
                            </li>
                            <li>Block or Report Users Features</li>
                            <li>Feedback and Reviews</li>
                            <li>Social Login and Sign Up</li>
                            <li> Profile Management</li>
                          </>
                        )}
                        {showMore ? (
                          <button
                            className="btn btn-light view-more-b mt-4 bg-bbdark"
                            onClick={handleShowLess}
                          >
                            Show Less
                          </button>
                        ) : (
                          <button
                            className="btn btn-light view-more-b mt-4 bg-bbdark"
                            onClick={handleShowMore}
                          >
                            View More
                          </button>
                        )}
                      </ul>
                    </p>
                    {/* <button className="btn btn-light view-more-b mt-4 bg-bbdark">View More</button> */}
                  </div>
                  <img
                    src={Display1}
                    alt="Display"
                    className="img-max-25 m-end"
                  />
                </div>
              </div>
            </div>
            <div className="row app-d">
              <div className="col-md-12">
                <div className="zigzag">
                  <div className="order-lg-last ">
                    <h1 className="subpage-heading heading-box-end head-box-redlt">
                      Vet Owner <br />
                      Mobile App&nbsp;
                    </h1>

                    <p className="para-grey">
                      <ul className="circle-list blue-circle-list f-38">
                        <li>Verification sign-up process for vets</li>
                        <li>
                          Pick pet owners' questions from the question
                          suggestions algorithm
                        </li>
                        <li>Real-time chat between vet and pet owner</li>
                        <li>Telehealth consultation via video call</li>
                        <li>Profile management</li>
                        {showMore2 && (
                          <>
                            <li>
                              Consultation attachments and medication notes for
                              telehealth
                            </li>
                            <li>
                              Real-time push notifications for chats and calls
                            </li>
                            <li>
                              {" "}
                              Explore pets and animals from any past calls
                            </li>
                            <li>
                              Track pet health using analytical data and graphs
                            </li>
                            <li>Reviews and feedback</li>
                            <li>Social login and sign-up</li>
                          </>
                        )}
                        {showMore2 ? (
                          <button
                            className="btn btn-light view-more-b mt-4 bg-bbdark"
                            onClick={handleShowLess2}
                          >
                            Show Less
                          </button>
                        ) : (
                          <button
                            className="btn btn-light view-more-b mt-4 bg-bbdark"
                            onClick={handleShowMore2}
                          >
                            View More
                          </button>
                        )}
                      </ul>
                    </p>
                    {/* <button className="btn btn-light view-more-b mt-4 bg-bbdark">View More</button> */}
                  </div>
                  <img
                    src={Display1}
                    alt="Display"
                    className="img-max-25 m-end"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="app-display">
        <div
          data-aos="fade-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <Container>
            <div className="row d-flex align-items-center admin-app">
              <div className="col-lg-12 ">
                <h1 className="subpage-heading heading-box text-start text-ablue">
                  Super Admin
                </h1>
                <div className="d-zgrid align-items-start justify-content-between">
                  <ul class=" list-circle-arrow2  f-38 t-i-0 ms-1">
                    <li>Vets and Pet Owner Management </li>
                    <li>Questions Management</li>
                    <li>Analytics Dashboard</li>
                    <li>Reports and Blocks</li>
                    <li>Vet Profile Verification </li>
                    <li>Support and Help</li>
                  </ul>

                  <img
                    src={SuperAdmin}
                    alt="Super Admin"
                    className=" image-top ms-auto"
                  />
                </div>
              </div>
            </div>
          </Container>
          <div className="webAppDisplay margin-top-50">
            <h1 className="subpage-heading heading-box text-center text-ablue">
              Web App
            </h1>
            <Container>
              <div className="row d-flex mt-4 project-pad">
                <div className="col-lg-5 ">
                  <h1 className="subpage-heading text-ablue">Pet owner</h1>
                  <ul class=" list-circle-arrow2 t-i-0 f-38 ms-0 ps-0">
                    <li>Create questions and get answers from vets </li>
                    <li>Subscriptions and purchase questions</li>
                    <li> Pet and farm animal management</li>
                    <li> Questions management</li>
                    <li> Profile management </li>
                    <li> Reviews and feedback</li>
                    <li> Social login and sign-up</li>
                  </ul>
                </div>
                <div className="col-lg-7 text-end">
                  <div className="appWauto left-pos ms-auto">
                    <img src={Petwebapp} alt="Pet App" className="mx-w-100" />
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center project-pad mt-120">
                <div className="col-lg-5 ">
                  <h1 className="subpage-heading text-ablue">Vet Doc</h1>
                  <ul class="list-circle-arrow2  t-i-0 f-38 ms-0 ps-0">
                    <li> Social login and sign-up</li>
                    <li> Answer pet questions</li>
                    <li> Profile management</li>
                    <li>Customers/client management</li>
                    <li>Review and feedback</li>
                  </ul>
                </div>
                <div className="col-lg-7 text-end order-lg-first">
                  <div className="appWauto right-pos ms-auto">
                    <img src={Petwebapp} alt="Pet App" className="mx-w-100" />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>

      <section className="app-services-block" id="Services">
        <div
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <Container>
            <div className="row d-flex align-items-center project-pad">
              <div className="col-lg-12 text-center">
                <h1 className="subpage-heading heading-box-end head-box-purple ">
                  Services we provide &nbsp;
                </h1>
                <div className="inner_contac ">
                  <div className="inner_contact">
                    <div className="inner_subappservice1 align-items-center justify-content-center">
                      <div className="appservice_grd text-ablue bg-paleyellow">
                        <div className="grd_txt">
                          <p>
                            Concept <br />
                            Brainstorming
                          </p>
                        </div>
                      </div>
                      <div className="appservice_grd text-ablue bg-ltred">
                        <div className="grd_txt">
                          <p>
                            App store & Play Store
                            <br />
                            publication assistance
                          </p>
                        </div>
                      </div>
                      <div className="appservice_grd text-ablue bg-abgrey">
                        <div className="grd_txt">
                          <p>
                            {" "}
                            UI/UX
                            <br />
                            graphics
                          </p>
                        </div>
                      </div>
                      <div className="appservice_grd text-ablue bg-altviolet">
                        <div className="grd_txt">
                          <p>Development </p>
                        </div>
                      </div>
                      <div className="appservice_grd text-ablue bg-a-ltgreen">
                        <div className="grd_txt">
                          <p>
                            Support and
                            <br />
                            maintenance
                          </p>
                        </div>
                      </div>
                      <div className="appservice_grd text-ablue bg-ltpnkred">
                        <div className="grd_txt">
                          <p>
                            Testing and
                            <br />
                            Review
                          </p>
                        </div>
                      </div>
                      <div className="appservice_grd text-ablue bg-a-ltblue">
                        <div className="grd_txt">
                          <p>
                            Wireframing and
                            <br />
                            Architectural Design
                          </p>
                        </div>
                      </div>
                      <div className="appservice_grd text-ablue bg-ltyellow">
                        <div className="grd_txt">
                          <p>
                            Website Deployement
                            <br />
                            Architectural Design
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="tech-stack-block bg-ltpnkred" id="techStack">
        <div
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <Container>
            <div className="row  project-pad">
              <div className="col-lg-12 text-center">
                <div className="techstack-app ">
                  <h1 className="subpage-heading heading-box-end head-box-redorangelt">
                    Tech Stack
                  </h1>
                  <div className="tech-stack-icons ">
                    <img src={ReactIcon} alt="icone" />
                    <img src={MernIcon} alt="icon" />
                    {/* <img src={NodeIcon} alt="iccon" /> */}
                    {/* <img src={MongoIcon} alt="icon" /> */}
                    <img src={FirebaseIcon} alt="icon" />
                    <img src={HashIcon} alt="icon" />
                    {/* <img src={MernIcon} alt="icon" /> */}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="delivery-dx bg-abgrey  ">
        <Container>
          <div className="row d-flex align-items-center">
            <div className="col-lg-8 ">
              <div className="delivery-text">
                <h3 className="text-ablue">
                  Launch your own <br />
                  Pawsome Pet Care App!
                </h3>
                <div className="mt-4">
                  <ButtonContact />
                </div>
              </div>
            </div>
            <div className="col-lg-4 py-4 text-center">
              <img src={Logoicon} alt="Logo" className="img-fluid" />
            </div>
          </div>
        </Container>
      </section>

      <Footer></Footer>
    </main>
  );
}
