import "./App.css";
import { useEffect } from "react";
import { Routes, Route, useLocation ,Navigate } from "react-router-dom";
import { Homepage } from "./Views/homepage";
import { Thuisbezorgd } from "./Views/food-ordering-and-delivery-app-development";
import { Deliverx } from "./Views/on-demand-pickup-and-delivery-app-development-services-company";
import { Thelovechain } from "./Views/decentralized-social-media-apps-development-company";
import { ZKCryptocurrency } from "./Views/multichain-crypto-wallet-app-development-company";
import { Augusta } from "./Views/digital-wellbeing-and-mental-health-app-development-company";
import { Yogavivo } from "./Views/health-and-fitness-mobile-app-development-company";
import { Isend } from "./Views/on-demand-transportation-and-logistics-app-development-company";
import { Metafit } from "./Views/ai-driven-body-measurement-app-for-perfect-clothing-fits";
import { Askavet } from "./Views/petcare-and-veterinary-consultation-app-development-company";
import { Reactapp } from "./Views/reactapp";
import { Flutterapp } from "./Views/flutterapp";
import { Nativeiosandroid } from "./Views/nativeiosandroid";
import Notfoundpage from "./Pages/Notfoundpage";
// import CookieBanner from './Component/CookieBanner';
import CookieConsent from "react-cookie-consent";
import BlogPage from "./Pages/blogs/BlogPage";
import BlogDetails from "./Pages/blogdetails/BlogDetails";

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <div className="CookeDiv">
        {/* Other components */}
        <CookieConsent
          location="bottom"
          buttonText="Got it!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B", bottom: "70px" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          <span className="cookieText">
          We use cookies to enhance your experience. By continuing to visit this
          site you agree to our use of cookies.
          </span>
          
        </CookieConsent>
      </div>

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route
          path="/food-ordering-and-delivery-app-development"
          element={<Thuisbezorgd />}
        />
        <Route
          path="/on-demand-pickup-and-delivery-app-development-services-company"
          element={<Deliverx />}
        />
        <Route
          path="/decentralized-social-media-apps-development-company"
          element={<Thelovechain />}
        />
        <Route
          path="/multichain-crypto-wallet-app-development-company"
          element={<ZKCryptocurrency />}
        />
        <Route
          path="/digital-wellbeing-and-mental-health-app-development-company"
          element={<Augusta />}
        />
        <Route
          path="/health-and-fitness-mobile-app-development-company"
          element={<Yogavivo />}
        />
        <Route
          path="/on-demand-transportation-and-logistics-app-development-company"
          element={<Isend />}
        />
        <Route
          path="/ai-driven-body-measurement-app-for-perfect-clothing-fits"
          element={<Metafit />}
        />
        <Route
          path="/petcare-and-veterinary-consultation-app-development-company"
          element={<Askavet />}
        />
        <Route path="/reactapp" element={<Reactapp />} />
        <Route path="/flutterapp" element={<Flutterapp />} />
        <Route path="/nativeiosandroid" element={<Nativeiosandroid />} />
        <Route path="/blog" element={<BlogPage/>} />
        <Route path="/blogdetails" element={<BlogDetails/>} />
        <Route path="/page-not-found" element={<Notfoundpage />} />
        <Route
        path="*"
        element={<Navigate to="/page-not-found" replace />}
      />
      </Routes>
    </>
  );
}

export default App;
