import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 
import "./home.css";

import "./subpages.css";
import Bannerimg from "../Assest/Images/iSend_banner.png"
import Ideaimg from "../Assest/Images/iSendidea.png";
import Solution from "../Assest/Images/solution-isend.png";
import ButtonContact from '../Component/ButtonContact';
import PhpIcon from "../Assest/Images/phpblack.png";
import Firebase from "../Assest/Images/firebase.png";
import CorrjavaIcon from "../Assest/Images/corejava.png";
import SwiftIcon from "../Assest/Images/swift_n.png";
import Gmaps from "../Assest/Images/googlemaps.png";
import GplaceIcon from "../Assest/Images/gplaceap.png";
import CodeigniterIcon from "../Assest/Images/codeigniter.png";
import Mysqlicon from "../Assest/Images/mysql.png";

import Bottomimg from "../Assest/Images/isend-feature.png";
import googlePlay from "../Assest/Images/googleplay.png";
import appleStore from "../Assest/Images/appstore.png";

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Hex from "../Assest/Images/hexred.png";
import Mphone from "../Assest/Images/mobileisend.png";



export function Isend(props) {
  useEffect(() => {
    AOS.init();
  }, [])


  React.useEffect(() => {
    document.title = 'On-demand Transportation & Logistics app development company';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Immanent Solutions is a logistics & transportation app development company specializing in courier, fleet management solutions. Explore iSend App for more information.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Logistic App Development Services, Fleet Management Solution, Fleet Management Mobile Apps, iSend Logistics App, On-Demand Fleet Tracking, Route Optimization Software, Fleet Management Asset Utilization Tools, Fleet Coordination Communication App');
  
    // Check if canonical link already exists
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://immanentmobile.dev/on-demand-transportation-and-logistics-app-development-company');
  

  }, []);

  return (  
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner justify-content-center">
    <div className="innerpagebanner_img img-top-0  text-center ">
        <div className="w-auto position-relative"><img
        // className="up-down"
        alt="Banner" src={Bannerimg} className="img-fluid center-img" />
        {/* <div className="app-store-icons right-0 left-0">
                  <a href="https://apps.apple.com/in/app/" target="_blank"><img src={appleStore} alt="Passenger app"/></a>
                  <a href="https://play.google.com/store/apps/" target="_blank"><img src={googlePlay} alt="Passenger app"/></a>
        </div> */}
        </div>
      </div>
   
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-blue " 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading text-white sentensecase">What is iSend<br />    
Driver Partner App?</h1>
      <p className="para-grey text-white">The iSend Driver Partner App is a mobile application designed for both Android and iOS 
platforms, offering users the ability to send parcels conveniently from one location to 
another. The app employs geolocation-based features, allowing users to input parcel 
details such as weight and size, choose pick-up and delivery locations, and receive 
calculated trip fares based on distance and item specifics.
</p>
    </Container>
  </div>
</section>



<section className="project-idea" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex  project-pad">
        <div className="col-lg-6 "><img src={Ideaimg} alt="Project Idea" className="img-max-70"/> </div>
        <div className="col-lg-6 pt-5 pt-sm-0">
          <h1 className="subpage-heading heading-box-end head-box-redlt"> Project Idea</h1>
           <p className="para-grey">
           The iSend Driver Partner App is a mobile 
application designed to facilitate the 
seamless transportation of parcels from one 
location to another. The app provides users 
with a user-friendly interface to send parcels 
by specifying details such as weight and size, 
choosing pick-up and delivery locations, and 
receiving calculated trip fares based on 
various factors. It leverages geolocation-
based features to enhance the overall user 
experience.

           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-12 pt-5 text-center">
         <h1 className="subpage-heading heading-box-end head-box-redlt">Challenges Faced</h1>
          <p className="para-grey text-center">
          Implementing precise fare calculation based on distance and item details posed a 
significant challenge. Additionally, optimizing application performance with a complex 
codebase proved to be a demanding task.

          </p>
        </div>
        
      </div>
      <div className="row d-flex   project-pad">
        <div className="col-lg-6 ">
       <h1 className="subpage-heading heading-box-end head-box-redlt">Solutions <br />
Implemented</h1>
        <p className="para-grey">
        To address fare calculation accuracy, the app 
leveraged the Google Geolocation API, ensuring 
precise distance calculations. Efficient data 
structures and algorithms were employed to optimize 
the codebase, ensuring smooth performance and a 
seamless user experience.

          </p>
        </div>
        <div  className="col-lg-6 text-end">
          <img src={Solution} alt="Solution" className="img-fluid"/>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="core-features bg-blue-lt" id="Features">
<div className="core-features-inner1">
    <Container>
      <div className="row mb-4">
          <div className="col-lg-12 text-center">
         <h1 className="subpage-heading text-blue">Main features<br/></h1>
          </div>
      </div>
      <div className="row mt-6">
        <div className="col-md-6">
          
          
              <div className="w-lg-90 ">
             <h1 className="subpage-heading heading-box-end head-box-redlt py-3">Customer App</h1>
                <p className="para-grey">
                <ul className=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
               <li>Rent a car, truck, or 
                  prepare any shipment for 
                  delivery</li>
               <li>Create a shipment by 
                    providing shipment/parcel 
                    details</li>
               <li>Queue delivery system</li>
               <li>Multi-stop features</li>
               <li>Real-time driver tracking</li>
               <li>Trip cost estimations based 
                    on trip distance and number of 
                    stops</li>
              <li>Search nearby delivery agents 
                    to confirm delivery</li>
               <li>Call driver</li>
               <li>Real-time push notifications</li>
               <li>Apple and Google login/
                    sign-up</li>
               <li>Trips management</li>
               <li>Real-time chat</li>
               <li>Review and ratings for drivers</li>
               
                
                </ul>
                </p>
              </div>
          
               

               
            
         
           
        </div>
        <div className="col-md-6">
              <div className="w-lg-90 ">
             <h1 className="subpage-heading heading-box-end head-box-redlt py-3">Driver App &nbsp;</h1>
                <p className="para-grey">
                <ul className=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
               <li>Vehicle management</li>
               <li>Offline/online mode to 
receive new ride requests</li>
               <li>Driver verification process 
by uploading supporting and 
identity documents</li>
               <li>Real-time call and chat 
options</li>
               <li>Accept and decline new 
shipments or ride requests</li>
               <li>Trips management</li>
               <li>Wallet management for 
earnings and analytics</li>
              
                
                </ul>
                </p>
              </div>
          
               <div className="bottom-img-app">
                  <img src={Bottomimg} alt="Trips" className="img-max-md50" />
               </div>

               
            
         
           
        </div>
      </div>
    </Container>
  </div>
</section>


<section className="app-services-block" id="Services">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center">
      <div className="col-lg-12 text-center">
       <h1 className="subpage-heading heading-box-end head-box-redlt ">Services we provide &nbsp;</h1>
        <div className="inner_contac ">
        <div className="inner_contact">
          <div className="inner_subappservice align-items-center">
            <div className="col-app">
                <div className="appservice_grd bg-blue text-white">
                <div className="grd_txt">
                    <p>Concept <br />Brainstorming</p>
                </div>
                </div>
                <div className="appservice_grd text-default  bg-blue-lt">
                <div className="grd_txt">
                <p>Development </p>
                </div>
                </div>
            </div>
          
           
            <div className="col-app ">
                <div className="appservice_grd text-white bg-blue">
                <div className="grd_txt">
                <p>Wireframing and 
    <br />Architectural Design</p>
                </div>
                </div>
                <div className="appservice_grd text-default bg-blue-lt">
                <div className="grd_txt">
                <p>App store & Play Store 
    <br />publication assistance</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-blue">
                <div className="grd_txt">
                <p>Support and 
    <br />maintenance</p>
                </div>
                </div>
            </div>
            <div className="col-app order-xl-3">
                <div className="appservice_grd  bg-blue-lt">
                <div className="grd_txt">
                <p> UI/UX 
    <br />graphics</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-blue">
                <div className="grd_txt">
                <p>Testing and 
                <br />Review</p>
                </div>
                </div>
            </div>
           
        
           
           
          </div>
        </div>
      </div>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="tech-stack-block bg-blue-lt" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-redlt">Tech Stack</h1>
          <div className="tech-stack-icons">
            <img src={CorrjavaIcon} alt="icone" className="img-fluid" />
            <img src={SwiftIcon} alt="iccon" className="img-fluid"/>
            <img src={Gmaps} alt="icon" className="img-fluid" />
            <img src={GplaceIcon} alt="icone" className="img-fluid" />
            <img src={PhpIcon} alt="iccon" className="img-fluid"/>
            <img src={CodeigniterIcon} alt="icone" className="img-fluid" />
            <img src={Mysqlicon} alt="icon" className="img-fluid" />
            <img src={Firebase} alt="iccon" className="img-fluid"/>
            
          </div>
          </div>
         </div>
      </div>
   
    </Container>
    <div className="rating-app2">
                
                <div className="hr-border-label2 border-color-maroon"> </div>
                <div className="rating-wrap"> 
                <Container className="d-grid h-100">
                  <div className="row">
                    <div className="col-md-4 xs-hide phone-left">
                    <img src={Mphone} alt="phoe icon" />
                    </div>
                      <div className="col-sm-12 col-md-11 col-lg-8 position-relative mx-auto">
                        <div className="labels-rating2  ">
                            <div className="rating-1">
                                <img src={Hex} alt="hex" className="" />
                            </div>
                            <div className="rating-2">
                                <img src={Hex} alt="hex" className="" />
                            </div>
                          <div className="rating-1-content rating-1-content-yg">
                                <div className="circle-rate border-color-maroon">
                                    3+
                                </div>
                                <div className="label-text">
                                  Rating
                                </div>
                          </div>
                          <div className="rating-2-content rating-2-content-yg">
                              
                                <div className="label-text">
                                  Downloads
                                </div>
                                <div className="circle-rate-2 bg-maroon">
                                    1K+
                                </div>
                          </div>
                        </div>
                       
                      </div>
                  </div>
                </Container> 
                </div>
                               
                
  </div>
  </div>
  
</section>

<section className="delivery-dx bg-maroon mb-4 mt-4 py-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-11 mx-auto text-center"> 
          <div className="delivery-text">
          <h3>Turn Your Delivery Idea into Reality. <br />
Launch your Delivery App Today! 
</h3>
              <div className="mt-4">
              <ButtonContact />
              </div>
          </div>
            
          </div>
         
      </div>
  </Container>
</section>



      
      <Footer></Footer>
  
    </main>
  )
}
