import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
import ANDR from "../Assest/Images/ANDORID1.png";
import IOS from "../Assest/Images/IOS1.png";

import Slide2 from "../Assest/Images/Yogavivo/2.png";
import Slide3 from "../Assest/Images/Yogavivo/3.png";
import Slide4 from "../Assest/Images/Yogavivo/4.png";
import Slide5 from "../Assest/Images/Yogavivo/5.png";
// import { Link } from "react-router-dom";

const slide8 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link
          to={"/health-and-fitness-mobile-app-development-company"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="health-and-fitness-mobile-app-development-company" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="health-and-fitness-mobile-app-development-company" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="health-and-fitness-mobile-app-development-company" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="health-and-fitness-mobile-app-development-company" src={Slide5} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                4<span>Months</span>
              </h4>
              <h3>YOGAVIVO</h3>
              <h5>Native iOS and Android</h5>
              <h1>Built with technology look up </h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Android</h4>
                  <ul>
                    <li>Core Java</li>
                    <li>MVVM Code Pattern</li>
                    <li>Android Framework & SDK</li>
                    <li>Vimeo Video Streaming Engine</li>
                    <li>ScreenCast Engine</li>
                    <li> Paypal Gateway</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>IOS</h4>
                  <ul>
                    <li>Swift 4</li>
                    <li>Vimeo Video Streaming Engine</li>
                    <li>MVC Code Pattern</li>
                    <li>SwiftUI</li>
                    <li> iOS Payment Gateways </li>
                    <li>iOS ScreenCast</li>
                    <li>iOS In-App Purchase</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>WordPress</li>
                    <li>PHPMyAdmin & SQL</li>
                    <li>Simple Membership Plugin</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span>
                      <a href="https://apps.apple.com/in/app/yogavivo/id1258590615">
                        <img src={IOS} alt="IOS" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.yogavivo&hl=en">
                        <img src={ANDR} alt="Android" />
                      </a>
                    </span>
                  </div>
                </div>

                <div className="col_g">
                  {/* <Link
                    to={"/health-and-fitness-mobile-app-development-company"}
                    className="showMore mt-0"
                    
                  >
                    Read the full case study
                  </Link> */}
                  <a
                  href="/health-and-fitness-mobile-app-development-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide8;
