import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
import ANDR from "../Assest/Images/ANDORID1.png";
// import IOS from "../Assest/Images/IOS1.png";

import Slide1 from "../Assest/Images/TLC/1.png";
import Slide2 from "../Assest/Images/TLC/2.png";
import Slide3 from "../Assest/Images/TLC/3.png";
import Slide4 from "../Assest/Images/TLC/4.png";
import Slide5 from "../Assest/Images/TLC/5.png";
// import { Link } from "react-router-dom";
// import { Thelovechain } from '../Views/decentralized-social-media-apps-development-company';

const slide7 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link
          to={"/decentralized-social-media-apps-development-company"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="decentralized-social-media-apps-development-company" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="decentralized-social-media-apps-development-company" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="decentralized-social-media-apps-development-company" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="decentralized-social-media-apps-development-company" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="decentralized-social-media-apps-development-company" src={Slide5} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                3<span>Months</span>
              </h4>
              <h3>THE LOVE CHAIN</h3>
              <h5>React Native</h5>
              <h1>Built with technology look up</h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Frontend</h4>
                  <ul>
                    <li>React Native Expo Framework (mobile)</li>
                    <li>Expo with TypeScript</li>
                    <li>Back4App SDK (backend communication)</li>
                    <li>Algorand SDK (blockchain operations)</li>
                  </ul>
                </div>

                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>Back4App</li>
                    <li>Algorand SDK</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span>
                      <a href="https://play.google.com/store/apps/details?id=com.love.chain">
                        <img src={ANDR} alt="Android" />
                      </a>
                    </span>
                  </div>
                </div>
                <div></div>
                <div className="col_g">
                  {/* <Link
                    to={"/decentralized-social-media-apps-development-company"}
                    className="showMore mt-0"
                    
                  >
                    Read the full case study
                  </Link> */}
                  <a
                  href="/decentralized-social-media-apps-development-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide7;
