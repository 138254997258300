import React from 'react';
import {Container} from "react-bootstrap"
import {Row} from "react-bootstrap"
import mainlogo from "../Assest/Images/footer_logo.png";
import LinkedIn from "../Assest/Images/linked_i.png";
import Facebook from "../Assest/Images/facebook_i.png";
import Instagram from "../Assest/Images/instagram_i.png";
import India from "../Assest/Images/India.png";
import Uae from "../Assest/Images/uae.png";
import Nav from 'react-bootstrap/Nav';
import WtsappIcon from "../Assest/Images/icons/whatsapp.png";
import SkypeIcon from "../Assest/Images/icons/skype.png";
import TeleIcon from "../Assest/Images/icons/tele.png"
export function Footer(props) {
    return (
        <>
        <div className="footer py-5">
    <Container>
        <Row>
            <div className="col-lg-5 text-light">
         
            <div className="career pt-0">
                <img src={India} alt='india' />
                <div>
                <p  className='text-white'>INDIA</p>
                <p> #B-926, Bestech Business Tower, Sector 66,  <br></br>  Sahibzada Ajit Singh Nagar,   <br></br>Punjab 160066 
                </p>
                </div>
           
              
              </div>
              <div className="career">
              <img src={Uae} alt='uae' />
              <div>
                <p className='text-white'>UAE</p>
            <p>#Compass Building, AI Shohada Road,<br></br>
            Al Hamra Industrial Zone-FZ, RAK,<br></br>
            United Arab Emirates AE 
            </p>
             </div>
              </div>
            </div>
            <div className="col-lg-3 text-light">
            <div className='foot_menu'>
                <h3>Quick Links </h3>
                  {/* <Nav.Link href="/">Home</Nav.Link> */}
                  <Nav.Link href="#techStack">Tech Stack</Nav.Link>
                  <Nav.Link href="/#portfolio">Portfolio</Nav.Link>
                  <Nav.Link href="/#testimonial">Testimonials</Nav.Link>
                  <Nav.Link href="/#Contact">Contact Us</Nav.Link>
            </div>
            </div>
            <div className="col-lg-4 text-light foot_text">
            <div className='foot_social_menu'>
            <div className='u-details'>
            <h3>CONNECT US</h3>
            <Nav.Link  href="mailto:development@immanentsolutions.com"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> development@immanentsolutions.com</Nav.Link>
            <Nav.Link href='tel:+917986808794'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg> +91-7986808794</Nav.Link>
            </div>
            <div className='social_icons_w'>
            <h6>Social Media Links</h6>
                <div class="social_icons">
                    <a  href="https://www.linkedin.com/company/immanentsolutions/" target='_blank'><img src={LinkedIn} alt='linkedin' /></a>
                    <a  href="https://www.facebook.com/immanentsolutions" target='_blank'><img src={Facebook} alt='facebook' /></a>
                    <a  href="https://www.instagram.com/immanent_sol/" target='_blank'><img src={Instagram} alt='instagram' /></a>
                    
            </div>
            </div>
                
                <img src={mainlogo} className="main-logo" alt="logo" />
            </div>
            </div>
            <div className="col-md-12 text-light copyrt">
            <p>Copyright © 2024 Immanent Solutions. All rights reserved.</p>
            </div>
        </Row>   
    </Container>
</div>
<div className='s-icons gap-2 fixed-icons '>
              <a href={"https://wa.me/917986808794"} target='_blank'><span><img src={WtsappIcon} alt='whatsapp' /></span></a>
              <a href="skype:neeraj.immanentsolutions?chat" target='_blank'> <span><img src={SkypeIcon} alt='Skype'/></span></a>
              <a href='https://t.me/ImmanentSolutions' target='_blank'><span><img src={TeleIcon} alt='Telegram'/></span></a>
            </div>

           <a href="#"><span className='go-to-top'><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
</svg></span> </a>
</>
    )
}