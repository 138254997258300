import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
import ANDR from "../Assest/Images/ANDORID1.png";
import IOS from "../Assest/Images/IOS1.png";

import Slide1 from "../Assest/Images/ZkCrypto/on-boarding.jpg";
import Slide2 from "../Assest/Images/ZkCrypto/wallet-connect-1.jpg";
import Slide3 from "../Assest/Images/ZkCrypto/wallet-connect-2.jpg";
import Slide4 from "../Assest/Images/ZkCrypto/settings.jpg";
import Slide5 from "../Assest/Images/ZkCrypto/Send.jpg";
import Slide6 from "../Assest/Images/ZkCrypto/home.jpg";
import Slide7 from "../Assest/Images/ZkCrypto/funds.jpg";
// import { Link } from "react-router-dom";

const slide9 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link
          to={"/multichain-crypto-wallet-app-development-company"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="multichain-crypto-wallet-app-development" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="multichain-crypto-wallet-app-development" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="multichain-crypto-wallet-app-development" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="multichain-crypto-wallet-app-development" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="multichain-crypto-wallet-app-development" src={Slide5} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="multichain-crypto-wallet-app-development" src={Slide6} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="multichain-crypto-wallet-app-development" src={Slide7} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                4<span>Months</span>
              </h4>
              <h3>ZK Cryptocurrency Wallet </h3>
              <h5>React Native </h5>
              <h1>Built with technology look up</h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Mobile App:</h4>
                  <ul>
                    <li>React Native</li>
                    <li>Typescript + JavaScript</li>
                    <li>Redux</li>
                    <li>Ether.js</li>
                    <li>Wallet Connect V2</li>
                    <li>CoinMarketCap API</li>
                  </ul>
                </div>

                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>NodeJS APIs</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span>
                      <a href="https://apps.apple.com/us/app/zk-cryptocurrency-wallet/id6451382590">
                        <img src={IOS} alt="IOS" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.zkwallet">
                        <img src={ANDR} alt="Android" />
                      </a>
                    </span>
                  </div>
                </div>
                <div></div>
                <div className="col_g">
                  {/* <Link
                    to={"/multichain-crypto-wallet-app-development-company"}
                    className="showMore mt-0"
                    // target="_blank"
                  >
                    Read the full case study
                  </Link> */}
                  <a
                  href="/multichain-crypto-wallet-app-development-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide9;
