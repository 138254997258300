import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
// import ANDR from "../Assest/Images/ANDORID1.png";
// import IOS from "../Assest/Images/IOS1.png";

import Slide1 from "../Assest/Images/iSend/D1.jpg";
import Slide2 from "../Assest/Images/iSend/D2.jpg";
import Slide3 from "../Assest/Images/iSend/D3.jpg";
import Slide4 from "../Assest/Images/iSend/D4.jpg";
import Slide5 from "../Assest/Images/iSend/D5.jpg";
import Slide6 from "../Assest/Images/iSend/p1.jpg";
import Slide7 from "../Assest/Images/iSend/p2.jpg";
import Slide8 from "../Assest/Images/iSend/p3.jpg";
import Slide9 from "../Assest/Images/iSend/p4.jpg";
import Slide10 from "../Assest/Images/iSend/p5.jpg";
import Slide11 from "../Assest/Images/iSend/p6.jpg";
// import { Link } from "react-router-dom";

const slide4 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link
          to={"/on-demand-transportation-and-logistics-app-development-company"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide5} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide6} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide7} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide8} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide9} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide10} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="on-demand-transportation-and-logistics-app-development" src={Slide11} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                9<span>Months</span>
              </h4>
              <h3>
                Passenger APP iSEND <br />
                DRIVER PARTNER APP{" "}
              </h3>
              <h5>Native iOS and Android</h5>
              <h1>Built with technology look up</h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Android</h4>
                  <ul>
                    <li>Core Java</li>
                    <li>Android SDK</li>
                    <li>Model View Model Controller</li>
                    <li>Realm Database</li>
                    <li>XML</li>
                    <li>Google Place API</li>
                    <li>Google Geolocation API</li>
                    <li>Twilio</li>
                    <li>Web Socket</li>
                    <li>Webhooks</li>
                    <li>MVVM Code base Structure</li>
                    <li>Android Framework</li>
                    <li>Android SDK</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>IOS</h4>
                  <ul>
                    <li>Swift 4</li>
                    <li>SwiftUI</li>
                    <li>Model View Controller</li>
                    <li>Core Data</li>
                    <li>Swift UI</li>
                    <li>Google Place API</li>
                    <li>Google Geolocation API</li>
                    <li>Twilio</li>
                    <li>Web Socket</li>
                    <li>Webhooks</li>
                    <li>NS User Defaults services</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>PHP with Codegniter framework</li>
                    <li>PHPMyAdmin + SQL</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Common Technology (Android + iOS): </h4>
                  <ul>
                    <li>Google Place APIs + Maps features </li>
                    <li>FCM Push Notifications </li>
                    <li>Realm Database </li>
                    <li>Location Tracking features</li>
                  </ul>
                </div>
                {/* <div className='col_g'>
            <h4>Platforms</h4>
            <div className="and_ios_icon"><span><img src={IOS} alt="IOS" /><img src={ANDR} alt="Android" /></span></div>
          </div> */}
                <div className="col_g">
                  {/* <Link
                    to={
                      "/on-demand-transportation-and-logistics-app-development-company"
                    }
                    className="showMore mt-0"
                    
                  >
                    Read the full case study
                  </Link> */}
                  <a
                  href="/on-demand-transportation-and-logistics-app-development-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide4;
