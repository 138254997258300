import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 

import "./home.css";
import "./subpages.css";
import Thuisimg from "../Assest/Images/thuis-banner.png"

import Ideaimg from "../Assest/Images/idea.png";
import Solution from "../Assest/Images/solution.png";
import CustomerDisplay from "../Assest/Images/customer-app.png";
import DriverDisplay from "../Assest/Images/thuis-driver-app.png";
import ThIcon from "../Assest/Images/thuis-icon.png";
import FlutterIcon from "../Assest/Images/flutter-icon.png";
// import MongoIcon from "../Assest/Images/mongodb-icon.png";
import MernIcon from "../Assest/Images/mern-icon.png";
// import NodeIcon from "../Assest/Images/nodejs-icon.png";
import Hex from "../Assest/Images/hex.png";

import googlePlay from "../Assest/Images/googleplay.png";
import appleStore from "../Assest/Images/appstore.png";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import ButtonContact from '../Component/ButtonContact';



export function Thuisbezorgd(props) {
  useEffect(() => {
    AOS.init();
  }, [])

  React.useEffect(() => {
    document.title = 'On-Demand Food ordering & delivery App Development Company';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Thuisbezorgd app, created by Immanent Solutions. Explore our Ready-made food delivery app clones inspired by Uber Eats, DoorDash, Zomato & more. Launch your food delivery app platform today!');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Food Delivery App Developers, food delivery app development agency , Thuisbezorgd App, Ready-Made Food Delivery App Clones, Food Delivery App Development Company');
  
  // Check if canonical link already exists
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.setAttribute('href', 'https://immanentmobile.dev/food-ordering-and-delivery-app-development');
  
  }, []);

  return (  
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner">
    <div className="innerpagebanner_img">
        <img
        // className="up-down"
        alt="Thuisbezorgd" src={Thuisimg} className="mx-w-100 ms-4" />
      </div>
      <div className="banner_text orange-banner-bg orange-banner-bg2 bg-orange"  data-aos="zoom-out" data-aos-delay="400">
        <div>
        <img src={ThIcon} alt="Thuisbezorgd Icon" className="img-fluid" />
        <h1>Thuisbezorgd.nl</h1>
        <p>
        is a region-specific online 
        food delivery application 
        that I played a key role in 
        developing.
        </p>
        </div>
        <div className="app-store-icons">
                  <a href="https://play.google.com/store/apps/details?id=com.takeaway.android" target="_blank"><img src={googlePlay} alt="Thuisbezorgd"/></a>
                  <a href="https://apps.apple.com/in/app/thuisbezorgd-nl/id329472759" target="_blank"><img src={appleStore} alt="Thuisbezorgd"/></a>
          </div>
      </div>
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-orange-light" 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading sentensecase">What is the <br />
Thuisbezorgd.nl App?</h1>
      <p className="para-grey">The Thuisbezorgd.nl app is an Android application that facilitates the online ordering of 
food from a wide range of restaurants across the Netherlands. Users can browse 
through thousands of restaurants, including popular ones like Domino's Pizza, New York 
Pizza, Subway, Wok2Go, Febo, and Bezorgbeer, to order dishes such as pizza, salad, 
burgers, kebabs, and more. The app allows users to easily navigate through restaurant 
options, choose their desired dishes, and make secure payments through credit cards, 
iDEAL, or PayPal. Additionally, it provides features like order tracking through Food 
Tracker®, account logins for easy reordering, and the option to either have the food 
delivered or pick it up.</p>
    </Container>
  </div>
</section>



<section className="project-idea" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex  ">
        <div className="col-lg-6 proj-block-small orange"><img src={Ideaimg} alt="Project Idea" className="img-fluid"/> </div>
        <div className="col-lg-6 mt-4">
          <h1 className="subpage-heading heading-box-end head-box-orange"> Project Idea</h1>
           <p className="para-grey">
           The Thuisbezorgd.nl app aims to streamline 
          the process of ordering food online, offering 
          users a convenient and efficient way to 
          explore various cuisines, choose their favorite 
          dishes, and have them delivered or ready for 
          pickup.
           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-12 text-center pt-5">
         <h1 className="subpage-heading heading-box-end head-box-orange">Challenges and Key Achievements</h1>
          <p className="para-grey text-center">
          We successfully optimized location tracking through the implementation of websockets, 
resulting in a substantial 70% reduction in Google Places API usage. Additionally, 
We enhanced the security of REST API communication by implementing end-to-end 
AES encryption. To improve user convenience, We seamlessly integrated Facebook 
login functionality into the system.
          </p>
        </div>
        
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-6 ">
       <h1 className="subpage-heading heading-box-end head-box-orange">Solutions</h1>
        <p className="para-grey">
          We successfully optimized location tracking through the implementation of websockets, 
resulting in a substantial 70% reduction in Google Places API usage. Additionally, 
We enhanced the security of REST API communication by implementing end-to-end 
AES encryption. To improve user convenience, We seamlessly integrated Facebook 
login functionality into the system.
          </p>
        </div>
        <div  className="col-lg-6 text-end">
          <img src={Solution} alt="Solution" className="img-fluid"/>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="core-features bg-orange-light" id="Features">
  <div>
    <Container>
      <div className="row ">
        <div className="col-lg-11 px-4 mx-auto">
          <div className="core-features-inner bg-orange-light">
          <div className=" w-auto d-flex justify-content-center p-3">
              <div>
             <h1 className="subpage-heading heading-box-end head-box-orange">Core features<br />
                of the Project</h1>
                <p className="para-grey">
                <ul>
               <li>Location-based services</li> 
                <li>Real-time delivery updates</li> 
                <li>E-commerce functionality</li> 
                <li>UI/UX design</li> 
                <li>REST API implementation</li> 
                
                </ul>
                </p>
              </div>
          
               

               
            </div>
          </div>
           
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="display-app mt-6" >
      <Container>
        <div className="row "> 
           
            <div className="col-lg-6 ps-3 ">
              <img src={DriverDisplay}  alt="Driver Display" className="img-fluid margin-top-20"/>
            </div>
            <div className="col-lg-6">
              <div className="heading-outside-bg outside-orange">
             <h1 className="subpage-heading heading-inside-bg ">Driver App</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
                <li>Order Management</li>
                <li>Time Management</li>
                <li>Earnings Report</li>
                <li>Ratings & Reviews</li>
                <li>Advanced Optimized Routing</li>
              </ul>
             </div>
            </div>
        </div>
        <div className="row project-pad"> 
           
           
            <div className="col-lg-6">
              <div className="heading-outside-bg outside-orange heading-outside-bg2">
             <h1 className="subpage-heading heading-inside-bg ">Customer App</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
                <li>Order Placement</li>
                <li>Cart Management</li>
                <li>Online Payment</li>
                <li>Real-Time Order Tracking</li>
                <li>Ratings & Reviews</li>
                <li>Locate Restaurants Nearby</li>
              </ul>
             </div>
            </div>
            <div className="col-lg-6 ps-3 text-end">
              <img src={CustomerDisplay}  alt="Customer Display" className="img-fluid margin-top-20"/>
            </div>
        </div>
      </Container>
</section>


<section className="app-services-block" id="Services">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center project-pad">
      <div className="col-lg-12 text-center">
       <h1 className="subpage-heading heading-box-end head-box-orange ">Services we provide &nbsp;</h1>
        <div className="inner_contac ">
        <div className="inner_contact">
          <div className="inner_subappservice ">
            <div className="appservice_grd bg-orange-light">
              <div className="grd_txt">
                <p>Concept <br />Brainstorming</p>
              </div>
            </div>
            <div className="appservice_grd bg-orange">
              <div className="grd_txt">
              <p>Wireframing and 
 <br />Architectural Design</p>
              </div>
            </div>
            <div className="appservice_grd bg-orange-light">
              <div className="grd_txt">
              <p> UI/UX 
 <br />graphics</p>
              </div>
            </div>
            <div className="appservice_grd bg-orange">
              <div className="grd_txt">
              <p>Development </p>
              </div>
            </div>
            <div className="appservice_grd bg-orange-light">
              <div className="grd_txt">
              <p>App store & Play Store 
 <br />publication assistance</p>
              </div>
            </div>
            <div className="appservice_grd bg-orange">
              <div className="grd_txt">
              <p>Testing and 
            <br />Review</p>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="tech-stack-block bg-orange-light" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-orange">Tech Stack</h1>
          <div className="tech-stack-icons">
            <img src={FlutterIcon} alt="icone" />
            {/* <img src={NodeIcon} alt="iccon" />
            <img src={MongoIcon} alt="icon" /> */}
            <img src={MernIcon} alt="icon" />
          </div>
          </div>
         </div>
      </div>
     
    </Container>
  <div className="rating-app">
                
                <div className="hr-border-label border-color-orange"> 
                <Container>
                  <div className="row">
                      <div className="col-lg-12">
                        <div className="labels-rating">
                            <div className="rating-1">
                                <img src={Hex} alt="hex" />
                            </div>
                            <div className="rating-2">
                                <img src={Hex} alt="hex" />
                            </div>
                          <div className="rating-1-content">
                                <div className="circle-rate border-color-orange">
                                    4.6
                                </div>
                                <div className="label-text">
                                  Rating
                                </div>
                          </div>
                          <div className="rating-2-content">
                              
                                <div className="label-text">
                                  Downloads
                                </div>
                                <div className="circle-rate-2 bg-orange">
                                    5M+
                                </div>
                          </div>
                        </div>
                       
                      </div>
                  </div>
                </Container>                 
                </div>
  </div>
  </div>
  
</section>
<section className="delivery-dx bg-orange  py-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-12 mx-auto text-center"> 
          
          <h1>Launch your own Zomato-like food <br />
delivery App Today!</h1>
         
              <div className="mt-4">
              {/* <Link className="btn btn-light contact-us-b" to={'/#Contact'}>Contact Us !</Link> */}
              <ButtonContact className="btn-light"/>
              </div>
          
            
          </div>
          
      </div>
  </Container>
</section>



      
      <Footer></Footer>
  
    </main>
  )
}
