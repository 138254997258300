// import React from "react";
import React, { useState } from 'react';
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Header";
import { Footer } from "../Component/Footer"; 

import "./home.css";

import Forms from "../Assest/Images/form.png"
import Mobile from "../Assest/Images/banner_image.png";
import Phoneicon from "../Assest/Images/phone-icon.png";
import Slide1 from '../Component/slide3';
import Slide2 from '../Component/slide6';

import { useEffect } from "react";
import { Container } from "react-bootstrap";
// import { PhoneInput } from 'react-international-phone';
// import 'react-international-phone/style.css';


export function Flutterapp(props) {
  useEffect(() => {
    AOS.init();
  }, [])

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  // const [phone, setPhone] = useState('');


  const [captcha, setCaptcha] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
      generateCaptcha();
  }, []);

  const generateCaptcha = () => {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const captchaLength = 6;
      let randomCaptcha = "";
      for (let i = 0; i < captchaLength; i++) {
          const randomNumber = Math.floor(Math.random() * chars.length);
          randomCaptcha += chars.substring(randomNumber, randomNumber + 1);
      }
      setCaptcha(randomCaptcha);
  };



  const handleInputChange = (e) => {
      setInputValue(e.target.value);
  };

  const handleReload = () => {
      generateCaptcha();
  };


  return (  
    <main>
<Header name="Immanent Solutions"></Header>

<section className="banner" id="home">
  <Container>
    <div className="inner_banner">
      <div className="banner_text"  data-aos="zoom-out" data-aos-delay="400">
       <h1 className="heading">Confronting 
 <br></br><span>Challenges?</span></h1>
        <ul className="list-circle-arrow">
          <li>Concerns related to user data and privacy?</li>
          <li>Issues with cross-device functionality?</li>
          <li>App performance concerns?</li>
          <li>Struggling with third-party service integration?</li>
          <li>Need captivating UI/UX design assistance?</li>
        </ul>
        <h3 className="solution-head">We Have Solutions!</h3>
        
        <a href="#Contact" className="book_your_call"><img className="phone-icon" alt="Phone Icon" src={Phoneicon} /> Book Your Call Today!</a>
      </div>
      <div className="banner_img">
        <img className="up-down" alt="Mobile" src={Mobile} />
      </div>
    </div>
  </Container>
</section>
<section className="collaborate-sec" 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className='heading_group'>
     <h1 className="bar_heading_dn text-center">Collaborative Excellence from Concept to Code</h1>
      <h3  className=" text-center">Join us, and let’s together </h3>
      </div>
      
      <p className="head_para">Embarking on the journey of creating your app becomes a partnership, not just a service. Our dedication to collaborative excellence guarantees an immersive experience at every stage of your app's creation. Imagine a process where communication flows seamlessly, every step is transparent, and there's an unwavering commitment to bringing your unique app vision to life. It's more than collaboration; it's a shared journey. Join us, and let's together elevate your app development experience to a level of excellence that goes beyond expectations. </p>
      
    </Container>
  </div>
</section>
<section className="portfolio_new" id="portfolio">
  <Container>
    <div className="inner_portfolio_new">
      <h3>Collaboration to Transformation</h3>
      <h1>SEE OUR MOBILE APP PORTFOLIO</h1>
    </div>
    <div className='port-app'>
    <Slide1 />
    <Slide2 />
    
    </div>
   
  </Container>
</section>



<section className="contact_frm" id="Contact">
<Container>
  <div className="inner_contact_frm" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <div className="contact_forms">
    <div className="contact_form_section">
   <div>
  

   </div>
            <div className="contact_form">
            
              <form onsubmit="" class="form-container">
             <h1 class="bar_heading">Let’s Get started!</h1>
                <div className="frms_grds">
                <div className="form-row">
                  <input type="text" id="form-name" className="form-textbox" required />
                  <label for="form-email" className="form-label">Name</label>
                </div>
                <div className="form-row">
                  <input type="text" id="form-phone" className="form-textbox" required />
                  {/* <PhoneInput
                    defaultCountry="india"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  /> */}
                  <label for="form-phone" className="form-label">Phone</label>
                </div>

                </div>
               
                <div className="form-row">
                  <input type="text" id="form-email" className="form-textbox" required />
                  <label for="form-email" className="form-label">E-mail</label>
                </div>
                {/* <div class="form-row">
                <div class="select">
                <select class="select-text form-textbox" required >
                <option value="" disabled selected></option>
                <option value="1">Services1</option>
                <option value="2">Services2</option>
                <option value="3">Services3</option>
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label">Services you are looking for</label>
                </div>
                </div>              */}
                <div className="form-row">
                <div class="form__group">
                <textarea id="message" class="form__field" placeholder="Share your app vision with us" rows="6"></textarea>
                <label for="message" class="form__label">Share Your App Vision With Us</label>
                </div>
                </div>
                <div className="captcha-d align-items-center">
               
                <div className="form-row">
               <div className='d-flex align-items-center gap-2'>
               <div id="CAPTCHA"><span>{captcha}</span></div>
               <button type='button' className="reload" onClick={handleReload}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></button>
              </div>
              </div>
              <div className="form-row">
              
               <div class="form__group">
                    <input
                        type="text"
                        value={''}
                      
                        onChange={handleInputChange}
                        className="form-textbox"
                        placeholder=''
                    />
                     <label for="form-phone" className="form-label">Type CAPTCHA Here</label>
                    {/* <button onClick={handleSubmit}>Submit</button> */}
                    
                    {errorMessage && <div>{errorMessage}</div>}
                    {successMessage && <div>{successMessage}</div>}
                    
                    {/* <button onClick={handleBack}>Back</button> */}
                </div>
                </div>
                  
                </div>
                <div class="form-row">
                  <input type="submit" value="SEND MESSAGE &rarr;" className="form-submit" />
                </div>
              </form>
            </div>
          </div> 



    </div>
      
   
  <div className="contact_frm_img">
  <img src={Forms} alt='' />
     
      
  </div>
  </div>
  </Container>
</section>
      
      
      <Footer></Footer>
  
    </main>
  )
}
