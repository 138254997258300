// import React from "react";
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import swal from "sweetalert";
import { Header } from "../Component/Header";
import { Footer } from "../Component/Footer"; 
import CalendlyButton from '../Component/CalendlyButton';
import  TestimonialSlider  from "../Component/testimonial1";

import Accordion from 'react-bootstrap/Accordion';
import "./home.css";
import Portfolio from "../Component/portfolio"
// import Portfoliocategorized from '../Component/Portfoliocategorized';
import Stack from "../Component/stack"
import Forms from "../Assest/Images/form.png"
import Mobile from "../Assest/Images/banner_image.png";
import Icon1 from "../Assest/Images/icon1.png";
import Icon2 from "../Assest/Images/icon2.png";
import Icon3 from "../Assest/Images/icon3.png";
import Icon4 from "../Assest/Images/icon4.png";
import Icon5 from "../Assest/Images/icon5.png";
import Icon6 from "../Assest/Images/icon6.png";
import Prof1 from "../Assest/Images/neeraj_sir.png";
import Prof2 from "../Assest/Images/priyaranjan.png";
import Prof3 from "../Assest/Images/sonakshi.png";
import Prof4 from "../Assest/Images/abhilasha.png";
import Prof5 from "../Assest/Images/harvinder.png";
import Prof6 from "../Assest/Images/nidhi.png";
import AIcon1 from "../Assest/Images/poc.png";
import AIcon2 from "../Assest/Images/mvp.png";
import AIcon3 from "../Assest/Images/tm.png"
import AIcon4 from "../Assest/Images/fpm.png"
import AIcon5 from "../Assest/Images/dtm.png"

import { Carousel } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
//import CircularProgress from "../../assets/images/giphy.gif";
import CircularProgress from "../Assest/Images/giphy.gif";




export function Homepage(props) {
  useEffect(() => {
    AOS.init();
  }, [])


  

    // contact id used 
    const contactRef = useRef(null);

    useEffect(() => {
      if (window.location.hash === '#Contact') {
        setTimeout(() => {
          contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 100); // Adjust the delay if needed
      }
    }, []);


     // portfolio id used 
  const portfolioRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === '#portfolio') {
      setTimeout(() => {
        portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Adjust the delay if needed
    }
  }, []);

    // portfolio id used 
     // testimonial id used 
  const testimonialRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === '#testimonial') {
      setTimeout(() => {
        testimonialRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Adjust the delay if needed
    }
  }, []);

    // testimonial id used 

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const [name, setName] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [ tableLoader , setTableLoader]= useState(false);

  const [captcha, setCaptcha] = useState('');
  const [inputValue, setInputValue] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
      generateCaptcha();
  }, []);


  React.useEffect(() => {
    document.title = 'Hire Cross-Platform App Developers: React Native & Flutter Development Experts';
    document.querySelector('meta[name="description"]').setAttribute('content', 'At Immanent Solutions, we specialize in React Native and Flutter app development, crafting high-quality mobile apps with stunning UI/UX design and efficient codebase. Hire dedicated mobile app experts now!');
    
  }, []);

  const generateCaptcha = () => {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const captchaLength = 6;
      let randomCaptcha = "";
      for (let i = 0; i < captchaLength; i++) {
          const randomNumber = Math.floor(Math.random() * chars.length);
          randomCaptcha += chars.substring(randomNumber, randomNumber + 1);
      }
      setCaptcha(randomCaptcha);
  };

  // const handleSubmit = () => {
  //     if (inputValue === "") {
  //         setErrorMessage("This Field Can't Be Empty");
  //         generateCaptcha();
  //         return;
  //     }
  //     if (inputValue !== captcha) {
  //         setErrorMessage("Try Again");
  //         setInputValue('');
  //         generateCaptcha();
  //         return;
  //     }
  //     setSuccessMessage("Success! CAPTCHA Verified.");
  //     setInputValue('');
  //     setCaptcha('');
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Reset all error messages
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setMessageError("");
    setCaptchaError("");
    setSuccessMessage("");

    // Perform form validation
    if (!name) {
      setNameError("Please enter your name");
      return;
    }
    if (!phone) {
      setPhoneError("Please enter your phone number");
      return;
    } 
    
    const phoneWithoutNonDigits = phone.replace(/\D/g, '');
    // Adjust the regex pattern to allow for 2 or 3 digits for the country code prefix
    if (!/^\d{12,13}$/.test(phoneWithoutNonDigits)) {
      setPhoneError('Please enter a valid phone number');
      return false;
    }
    if (!email) {
      setEmailError("Please enter your email");
      return;
    } 
    //  if (!/^\S+@\S+\.\S+$/.test(email)) {
    //   setEmail("");
    //   setEmailError('Please enter a valid email address');
    //   return false;
    // }
   
    if (!message) {
      setMessageError("Please share your app vision");
      return;
    }
    if (captchaInput === "") {
      setCaptchaError("Please enter the captcha");
        generateCaptcha();
        return;
    }
    if (captchaInput !== captcha) {
     
      setCaptchaError("Invalid captcha! Try Again");
        generateCaptcha();
        return;
    }

    // Additional validation can be added as needed

    // If validation passes, proceed with form submission
    // Your submission logic goes here
    //var { name,email,phoneCountry,phone,message } = e.target;
    //console.log(e.target.name.value);
    const formData = new FormData();
    formData.append("name",e.target.name.value);
    formData.append("email",e.target.email.value);
    formData.append("phoneCountry",e.target.phoneCountry.value);
    formData.append("phone",e.target.phone.value);
    formData.append("message",e.target.message.value);
    //console.log("formDataformDataformData",formData);
    setTableLoader(true);
    axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/api/user/contactus`, formData).then((res) => {
    
      setTableLoader(false);
      const response = res.data;
      if (response.status == 0) {
          //setSuccessMessage(response.errors);
          swal("error", "Something went wrong!", "error");
      } else {
        //setSuccessMessage("Form submitted successfully!");
        swal("success", "Form submitted successfully!", "success");
      }
  });
   

    

    // Reset form fields after submission
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setCaptchaInput("");
  };

  const handleInputChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleReload = () => {
      generateCaptcha();
  };
  
  
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const nextSlide = () => {
    const newIndex = index + 1;
    if (newIndex < 6) {
      setIndex(newIndex);
    }
  };

  const prevSlide = () => {
    const newIndex = index - 1;
    if (newIndex >= 0) {
      setIndex(newIndex);
    }
  };
  
  return (  
    <main>




<Header name="Immanent Solutions"></Header>

<section className="banner" id="home">
  <Container>
    <div className="inner_banner">
      <div className="banner_text"  data-aos="zoom-out" data-aos-delay="400">
       <h2 className="heading">Confronting 
 <br></br><span>Challenges?</span></h2>
        <ul className="list-circle-arrow">
          <li>Concerns related to user data and privacy?</li>
          <li>Issues with cross-device functionality?</li>
          <li>App performance concerns?</li>
          <li>Struggling with third-party service integration?</li>
          <li>Need captivating UI/UX design assistance?</li>
        </ul>
        <h3 className="solution-head">We Have Solutions!</h3>
 
        <CalendlyButton />
        {/* <button onclick="Calendly.showPopupWidget('https://calendly.com/coffee-shop/coffee-cupping');return false;" className="book_your_call">
          <img className="phone-icon" alt="phone-icon" src={Phoneicon} /> Book Your Call Today!</button> */}
      </div>
      <div className="banner_img">
        <img className="up-down" alt="Mobile" src={Mobile} />
      </div>
    </div>
  </Container>
</section>
<section className="collaborate-sec" 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className='heading_group'>
     <h1 className="bar_heading_dn text-center">Leading Cross-Platform Mobile App Development Company</h1>
  
      </div>
      
      <p className="head_para">We are a prominent player in the mobile app development industry, focusing on cross-platform solutions that harness the latest advancements in technology, including React Native and Flutter. Our team consists of seasoned professional’s adept in React Native and Flutter, guaranteeing the creation of top-tier, scalable, and feature-rich mobile applications. Whether you seek to launch a novel app or enhance an existing one, we possess the expertise and know-how to realize your vision. Engage our proficient cross-platform app developers today and propel your mobile app concept to new heights! Reach out to us now to initiate a discussion about your project requirements. </p>
      
    </Container>
  </div>
</section>
<section className="portfolio_new" id="portfolio" ref={portfolioRef}>
  <Container >
    <div className="inner_portfolio_new">
      <h3>Collaboration to Transformation</h3>
      <h2>SEE OUR MOBILE APP PORTFOLIO</h2>
    </div>
  <Portfolio />
  {/* <Portfoliocategorized /> */}
  </Container>
</section>
<section id="hire-us">
 
    <Container>
      <div className='heading_group'>
     <h1 className="bar_heading_dn text-center">Hire Top React Native and Flutter Developers for Your Project</h1>  
      </div>      
      <p className="head_para">Here's our streamlined approach to hiring dedicated mobile app experts/team. We understand that finding the right talent can be challenging, which is why we've tailored our process with your preferences in mind. </p>
      
    </Container>
    <div className='hire-profile'>
        <Container>
          <div className='row'>
            <div className='col-lg-12'>
               <p className="head_para steps-para text-start">
               <span>Step 1:</span> Clearly outline your project goals, features and functionalities. Determine the specific skills and expertise required for your mobile app development project, such as React Native or Flutter development, UI/UX design, backend development, etc. Any of our mobile app development consulting experts will assist you with that.
                </p> 
                <p className="head_para steps-para text-start">
               <span>Step 2:</span> Our mobile app consulting experts will provide resumes from our in-house team.
                </p> 
              <div>
                <div className="custom-carousel-container" id="hire-us-profile">
              <Carousel className="custom-carousel"  activeIndex={index} onSelect={handleSelect}>
                  <Carousel.Item>
                    <div className="d-flex profile-slide">
                      <div className="col-md-4">
                        <img src={Prof1} className="img-fluid" alt="Prof1" width="348" height="398" />
                      </div>
                      <div className="col-md-8 ">
                        <h3>Neeraj Kumar <span>Sr. Mobile App Developer</span> </h3>
                        <p>With a deep understanding of mobile architecture and design patterns, Neeraj excels in optimizing app performance and scalability while adhering to best practices in code quality and security. Additionally, Neeraj is experienced in integrating complex backend systems, APIs, and third-party services to deliver seamless user experiences across multiple platforms.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="d-flex profile-slide orangelt">
                    <div className="col-md-4">
                    <img src={Prof2} className="img-fluid" alt="Priyaranjan" width="348" height="398" />
                      </div>
                      <div className="col-md-8 ">
                        <h3>Priyaranjan<span>Sr. React Native/Flutter Developer</span> </h3>
                        <p>Priyaranjan is an expert in cross-platform app development, specializing in React Native and Flutter frameworks. Priyaranjan leverages the power of React Native and Flutter to create high-performance apps that run seamlessly on both iOS and Android platforms. His expertise includes state management, UI/UX design, and optimizing app performance for a superior user experience.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="d-flex profile-slide pinklt">
                    <div className="col-md-4">
                    <img src={Prof3} className="img-fluid" alt="Sonakshi" width="348" height="398" />
                      </div>
                      <div className="col-md-8 ">
                        <h3>Sonakshi <span>Sr. Flutter/ Native Android Expert</span> </h3>
                        <p>Sonakshi is a seasoned Flutter developer with a profound understanding of Dart programming language and its essential role in Flutter development. With Dart, she efficiently implements Flutter's widget-based architecture, leveraging its features to create visually appealing and feature-rich mobile applications. Sonakshi specializes in implementing complex UI designs, and seamlessly integrating native device functionalities within Flutter apps.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="d-flex profile-slide purplelt">
                    <div className="col-md-4">
                    <img src={Prof4} className="img-fluid" alt="Abhilasha" width="348" height="398" />
                      </div>
                      <div className="col-md-8 ">
                        <h3>Abhilasha <span>Sr. Native iOS/Android Expert</span> </h3>
                        <p>Abhilasha is a proficient native mobile app developer with expertise in iOS and Android platforms. She has a comprehensive understanding of platform-specific languages and frameworks, including Swift, Objective-C, Java, and Kotlin. Abhilasha excels in building high-quality native apps that leverage the unique capabilities of each platform, ensuring optimal performance and user experience.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="d-flex profile-slide">
                    <div className="col-md-4">
                    <img src={Prof5} className="img-fluid" alt="Harvinder" width="348" height="398" />
                      </div>
                      <div className="col-md-8 ">
                        <h3>Harvinder <span>Sr. UI/UX Designer</span> </h3>
                        <p>Harvinder, a proficient UI/UX designer, brings a wealth of experience in crafting mobile app interfaces that prioritize user experience. By incorporating user-centric design principles, Harvinder ensures that every aspect of the interface enhances usability and fosters a delightful user journey. Collaborating closely with development teams, he transforms concepts into practical and visually appealing designs that elevate the overall user experience of mobile applications.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="d-flex profile-slide redlt">
                    <div className="col-md-4">
                    <img src={Prof6} className="img-fluid" alt="Nidhi" width="348" height="398" />
                      </div>
                      <div className="col-md-8 ">
                        <h3>Nidhi  <span>Sr. Quality Assurance</span> </h3>
                        <p>Nidhi is a seasoned mobile app tester with a wealth of experience in quality assurance and testing methodologies. She possesses expertise in both manual and automated testing techniques, enabling comprehensive testing coverage across diverse mobile platforms. Nidhi's thorough approach to bug identification and reporting ensures the delivery of high-quality, error-free mobile applications.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  {/* Add more Carousel.Items as needed */}
                </Carousel>
                <div className="custom-carousel-indicators">
                  <span className={`custom-carousel-arrow ${index === 0 ? 'disabled' : ''}`} onClick={prevSlide}>&#8592;</span>
                  <span className='paging-n'><span className='current-index'>{index + 1}</span> / 6</span> {/* Update the total number of slides accordingly */}
                  <span className={`custom-carousel-arrow ${index === 5 ? 'disabled' : ''}`}  onClick={nextSlide}>&#8594;</span>
                </div>
                </div>
                <div>
                  <p>
                  Review each candidate's skills, experience, and expertise to ensure alignment with your project requirements. Specifically, look for experience in developing similar mobile applications using React Native, Flutter, or other relevant technologies.
                  </p>
                </div>
              </div>
              <p className="head_para steps-para text-start">
               <span>Step 3:</span> Shortlist Candidate(s). 
                </p>
                <p className="head_para steps-para text-start">
               <span>Step 4:</span> Discuss pricing models, payment terms, and project timelines with the selected candidate. We can sign an NDA upon the client's demand.
                </p> 
                <p className="head_para steps-para text-start">
               <span>Step 5:</span> Start Collaboration. Establish clear communication channels, and set milestones for project delivery.
                </p> 
                <p className="head_para steps-para text-start">
               <span>Step 6:</span> Regularly monitor the progress of your project. Maintain open communication throughout the development process to address any issues or concerns promptly.
                </p>  
            </div>
          </div>
        </Container>
    </div>
</section>
<section className='pricing-sec'>
          <Container>
            <div className='row'>
                <div className='col-lg-12'>
                   <h2 className='bar_heading_dn'>PRICING</h2>
                  <div className='accordion-pricing'>
                  <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header><span>1. PoC Development </span><img src={AIcon1} className='img-fluid' alt="PoC Development" /></Accordion.Header>
                <Accordion.Body>
                Transform your idea into reality with our Proof of Concept (PoC) Development services, starting from just $1500. PoC helps in validating the feasibility of your app idea before committing significant resources. It allows you to test the concept's viability and identify potential technical challenges early on.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header><span>2. MVP Development</span> <img src={AIcon2} className='img-fluid' alt="MVP Development" /></Accordion.Header>
                <Accordion.Body>
                Kickstart your project journey with our Minimum Viable Product (MVP) Development solutions, available from $2500 onwards. By launching an MVP, you can test your app's viability in the market and validate assumptions about your target audience and market segment. This helps in making data-driven decisions and pivoting if necessary.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header><span>3. Time and Materials Model </span><img src={AIcon3} className='img-fluid' alt="Time and Materials Model" /></Accordion.Header>
                <Accordion.Body>
                Hourly Rates ranging from $10 - $25 <br />
In this model, the client pays for the actual time and resources spent on the project. The cost is calculated based on the number of hours worked and the hourly rate of the developers. This model provides flexibility and is ideal for projects with evolving requirements.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header><span>4. Fixed Price Model </span><img src={AIcon4} className='img-fluid' alt="Fixed Price Model" /></Accordion.Header>
                <Accordion.Body>
                $4000 onwards (pricing may vary based on features list) <br />
In this model, the project requirements, timeline, and budget are defined upfront, and the client pays a fixed price for the entire project. This model is suitable for well-defined projects with clear requirements.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header><span>5. Dedicated Team Model</span> <img src={AIcon5} className='img-fluid' alt="Dedicated Team Model" /></Accordion.Header>
                <Accordion.Body>
                In this model, the client hires a dedicated team of developers, designers, and other specialists who work exclusively on their project.  Tailored pricing - Contact our sales team.
                </Accordion.Body>
              </Accordion.Item>



              {/* Add more Accordion.Items here */}
            </Accordion>
                  </div>
                </div>
            </div>
          </Container>
</section>


<section className="app_service" id="MobileAppService">
  <div className="inner_app_service" data-aos="zoom-out" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="bar_heading_dn">Mobile App Development Services </h1>
      <p className="head_para">We create digital experiences that leave a lasting impression. Our mobile app development services redefine the way businesses connect with their users.</p>
      <div className="service_grd">
        <div className="serv">
          <h3>Mobile App Consulting</h3>
          <p>From ideation to post-launch, we provide strategic insights, ensuring your decisions align with your business goals.</p>
        </div>
        <div className="serv">
          <h3>MVP Development</h3>
          <p>Kickstart your app journey with our MVP development services. We turn your concepts into a functional prototype, PoC allowing you to test, iterate, and gather crucial user feedback before full-scale deployment.   </p>
        </div>
        <div className="serv">
          <h3>Cross-platform Expertise</h3>
          <p>Escape platform limitations with our cross-platform development expertise. Using React Native and Flutter, we build apps that run seamlessly on both iOS and Android, saving time and costs.</p>
        </div>
        <div className="serv">
          <h3>End to End Encryption</h3>
          <p>Immerse users in platform-specific experiences with our native app development. We leverage iOS and Android features to create apps with unparalleled performance and user satisfaction.</p>
        </div>
        <div className="serv">
          <h3>App Migration</h3>
          <p>Upgrade seamlessly with our app migration services. Whether shifting platforms or modernizing tech stacks, we ensure a smooth transition without compromising data integrity or user experience.</p>
        </div>
        <div className="serv">
          <h3>UI/UX Excellence </h3>
          <p>User satisfaction is at the forefront of our design philosophy. Our UI/UX experts collaborate with you to create visually stunning interfaces that enhance user engagement and ensure a seamless navigation experience. </p>
        </div>
      </div>
    </Container>
  </div>
</section>
<section className="Stack" id="techStack">
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h2 className="bar_heading_dn">TECHNOLOGY STACK</h2>
      <p className="head_para">We are proficient in building high-quality cross-platform apps with speed, accuracy, and quality that accommodate native 
      functionalities and features.</p>
    </Container>
  <div className="stack_sl">
    <div className="innerstack_sl">
      <Container>
      <Stack />
      </Container>
    </div>
  </div>
  </div>
</section>
<section className="appDevProcess" id="appDevProcess">
<div className="inner_app_service" data-aos="zoom-out" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
   <h2 className="bar_heading_dn"><span></span>Our App Development Process</h2>
      <p className="head_para">We create digital experiences that leave a lasting impression. Our mobile app development services redefine the way businesses connect with their users.</p>
      <div className="appdevprocess_inner">
            <div className="innerapp_service">
              <div className="app_process">
                <img src={Icon1} alt="icon" />
                <h3>App Discovery</h3>
              </div>
              <div className="app_process">
              <img src={Icon2} alt="icon" />
                <h3>UI/UX Design</h3>                
              </div>
              <div className="app_process">
              <img src={Icon3} alt="icon" />
                <h3>Multiplatform Development</h3>                
              </div>
              <div className="app_process">
              <img src={Icon4} alt="icon" />
                <h3>Quality Assurance</h3>                
              </div>
              <div className="app_process">
              <img src={Icon5} alt="icon" />
                <h3>App Store Submission</h3>                
              </div>
              <div className="app_process">
              <img src={Icon6} alt="icon" />
                <h3>Post Launch Maintenance</h3>                
              </div>
            
            </div>
      </div>
    </Container>
</div>
</section>
<section className="testi_monial" id="testimonial" ref={testimonialRef}>
  <div className="inner_testi_monial" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h2 className="bar_heading"><span>Reviews & Clients</span>Building trust with transparency</h2>
      <p className="head_para">Where every review tells a story, and every client is a chapter of success.</p>
    </Container>
  <div className="testi_monial_sl">    
      <TestimonialSlider />
     
  </div>
  </div>
</section>



<section className="faq" id="FAQ">
      <Container>
        <div className="inner_faq" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
          <div className="faq_title">
           <h2 className="bar_heading"><span>Frequently Asked</span> Questions</h2>
           
          </div>
          <div className="faq_list items">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>How does your mobile app development process work, and what steps are involved from concept to deployment?</Accordion.Header>
                <Accordion.Body>
                  Our process involves thorough planning, design brainstorming, followed by the development, rigorous testing, and seamless deployment phases, ensuring a systematic and efficient approach to deliver high-quality mobile applications.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Can you provide a transparent breakdown of the costs involved in developing a mobile app, including any potential additional expenses?</Accordion.Header>
                <Accordion.Body>
                Absolutely. We offer a detailed cost breakdown, covering development, testing, maintenance, and any potential additional expenses, ensuring transparency and alignment with your budget.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Do you assist with app store submissions and approvals?</Accordion.Header>
                <Accordion.Body>
                Yes, we not only assist but guide our clients through the entire app submission process, covering platforms such as the App Store and Google Play. Our expertise ensures strict compliance with guidelines, significantly increasing the likelihood of a successful approval for your app.  
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>What technologies and programming languages do you specialize in for mobile app development?</Accordion.Header>
                <Accordion.Body>
                Our team excels in a diverse range of technologies and programming languages tailored for mobile app development. This includes Swift and Objective-C for iOS, Kotlin and Java for Android, as well as expertise in cross-platform development using React Native, Flutter, and Xamarin, among other cutting-edge technologies.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>What security measures are in place to protect user data and ensure the overall security of the mobile applications you develop?</Accordion.Header>
                <Accordion.Body>
                Security is paramount. We implement robust encryption, secure authentication, and comply with industry standards to safeguard user data throughout the mobile app development lifecycle.
                </Accordion.Body>
              </Accordion.Item>



              {/* Add more Accordion.Items here */}
            </Accordion>
            {!showMore && (
              <div>
                <a href="javascript:;" className="showMore" onClick={toggleShowMore}>Show More</a>
              </div>
            )}
            {showMore && (
              <div>
                <Accordion defaultActiveKey="0" flush>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>Do you specialize in cross-platform development, and how do you ensure compatibility across different devices and operating systems?</Accordion.Header>
                  <Accordion.Body>
                  Yes, we specialize in cross-platform development, ensuring compatibility through frameworks like React Native, Flutter. Our approach guarantees a consistent experience across various devices and operating systems. Review our <a href="#portfolio">Portfolio</a> . 
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>How do you manage project timelines, and what steps are taken to ensure timely delivery of mobile app projects?</Accordion.Header>
                  <Accordion.Body>
                  We employ agile project management methodologies, set realistic timelines, and maintain constant communication to ensure transparency and meet deadlines for the successful delivery of mobile app projects.
                  </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="7">
                  <Accordion.Header>What kind of post-launch support and maintenance services do you offer to ensure the longevity and success of the developed mobile apps?</Accordion.Header>
                  <Accordion.Body>
                  Our post-launch support includes regular maintenance, updates, and troubleshooting to ensure the sustained success and functionality of your mobile apps (If we undertake the commitment to deliver post-launch support).
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>How flexible is your development process in accommodating unique app features and customization requests from clients?</Accordion.Header>
                  <Accordion.Body>
                  Extremely flexible. We prioritize client input and customization, tailoring our development process to accommodate unique features and specific requirements for a personalized mobile app solution.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                  <Accordion.Header>How will communication be handled throughout the development process, and what collaboration tools do you use to keep clients informed and involved?</Accordion.Header>
                  <Accordion.Body>
                    <p>Communication is key. We utilize collaborative tools, regular status updates, and client meetings to ensure open communication, keeping clients informed and involved throughout the mobile app development process.</p>
                    <p> <strong>We leverage a variety of tools, including but not limited to:</strong> </p>
                    <p><strong>Project Management Tools:</strong> Such as Jira, Trello, or Asana, for transparent task tracking and project progress updates.</p>
                    <p><strong>Communication Platforms:</strong> Such as Slack, Microsoft Teams, or Discord, facilitating real-time communication and instant collaboration among team members.</p>
                    <p><strong>Version Control Systems:</strong> Like Git and GitHub, ensuring seamless collaboration and tracking changes in the source code.</p>
                    <p><strong>Video Conferencing Tools:</strong> Such as Zoom, Google Meet, or Microsoft Teams, for conducting client meetings and virtual collaboration sessions.</p>
                    <p><strong>Document Sharing and Collaboration Tools:</strong> Such as Google Workspace or Microsoft 365, enabling collaborative document creation, editing, and sharing.</p>
                    <p><strong>Feedback and Annotation Tools:</strong> Such as Figma, allowing clients to provide feedback directly on design mockups and prototypes.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                  <Accordion.Header>Can you explain your approach to UI/UX design and how you ensure the mobile app is user-friendly and visually appealing?</Accordion.Header>
                  <Accordion.Body>
                  Our UI/UX design approach involves user research, wireframing, and iterative prototyping, ensuring a user-friendly and visually appealing mobile app that aligns with your brand.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                  <Accordion.Header>What strategies do you employ to address concerns related to app performance and potential bugs after launch?</Accordion.Header>
                  <Accordion.Body>
                  Our post-launch strategy includes continuous monitoring, proactive bug fixing, and regular updates to ensure sustained app performance and address any issues promptly.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                  <Accordion.Header>How do you handle scalability challenges to accommodate potential growth in user numbers for our mobile app?</Accordion.Header>
                  <Accordion.Body>
                  We design our mobile apps with scalability in mind, utilizing cloud infrastructure and scalable architectures to accommodate increasing user numbers and demand.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="13">
                  <Accordion.Header>What is your policy regarding intellectual property rights, and how will our app's code and assets be secured?</Accordion.Header>
                  <Accordion.Body>
                  You retain full ownership of your app's intellectual property. We ensure the secure handling of code and assets, and we can discuss specific IP protection measures as needed.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="14">
                  <Accordion.Header>How do you handle potential conflicts or changes in project requirements during the development process?</Accordion.Header>
                  <Accordion.Body>
                  We maintain open communication channels, and our agile development approach allows for flexibility in accommodating changes, ensuring smooth adaptation to evolving project requirements.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="15">
                  <Accordion.Header>Can you provide references from previous clients?</Accordion.Header>
                  <Accordion.Body>
                  Absolutely. We're more than happy to share references, and our track record is a testament to consistently positive feedback from satisfied clients. Their success stories speak to the quality and reliability of our mobile app development services.
                  </Accordion.Body>
                </Accordion.Item>

                  {/* Render additional FAQ items here */}
                </Accordion>
                <a href="javascript:;" className="showMore" onClick={toggleShowMore}>Show Less</a>
              </div>
            )}
          </div>
        </div>
      </Container>
    </section>



<section className="Contact">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="bar_heading"><span>Consultation</span>Schedule a Consultation</h1>
      <p className="head_para">Shaping Exceptional Mobile Experiences, One App at a Time.</p>
      <div className="inner_contac">
        <div className="inner_contact">
          <div className="inner_react_service">
            <div className="react_service_grd">
              <div className="grd_txt">
                <h2>WE ADAPT TO YOUR TIME ZONE</h2>
                <p>Our team operates in the IST (Indian Standard Time) timezone. We are dedicated to adjusting our schedule to accommodate yours, ensuring seamless communication despite any time differences.</p>
              </div>
            </div>
            <div className="react_service_grd">
              <div className="grd_txt">
                <h2>FACE-TO-FACE DISCUSSIONS</h2>
                <p>Schedule a pre-project kickoff meeting with our top resource for effective communication.</p>
              </div>
            </div>
            <div className="react_service_grd">
              <div className="grd_txt">
                <h2>CUSTOM PRICING MODEL</h2>
                <p>Choose from a Fixed Price Model, Hourly Model, Dedicated Team, or customized combinations to suit your unique needs.</p>
              </div>
            </div>
            <div className="react_service_grd">
              <div className="grd_txt">
                <h2>WELL-DEFINED ESTIMATE</h2>
                <p>Utilizing your requirements as a foundation, we thoroughly document and provide you with the most accurate project quote. </p>
              </div>
            </div>
            <div className="react_service_grd">
              <div className="grd_txt">
                <h2>REPORTS ON YOUR TERMS</h2>
                <p>Receive weekly updates featuring detailed task breakdowns to keep you informed and in control. </p>
              </div>
            </div>
            <div className="react_service_grd">
              <div className="grd_txt">
                <h2>POST-LAUNCH SUPPORT</h2>
                <p>Even after project completion, we are eager to implement your improvement ideas for sustained success.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="contact_frm" id="Contact" 
ref={contactRef}
>
<Container>
  <div className="inner_contact_frm">
    <div className="contact_forms">
    <div className="contact_form_section">
   <div>
  

   </div>
            <div className="contact_form">
            
              <form onSubmit={handleSubmit} class="form-container">
             <h2 class="bar_heading">Quick Contact</h2>
                <div className="frms_grds">
                <div className="form-row">
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-textbox"
                  
                />
                <label htmlFor="form-name" className="form-label">
                  Name
                </label>
                
                {nameError && !name && <ErrorTextShow PClass="error-message" PText={nameError} />}
                </div>
                <div className="form-row">
                  {/* <input type="text" id="form-phone" className="form-textbox" required />
                  <label for="form-phone" className="form-label">Phone</label> */}
                   
                    <PhoneInput
                    name="phone"
                    className="form-textbox flag-input"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                    defaultCountry="IN"
                  />
                 
                
                  {phoneError && (!phone || phoneError) && <ErrorTextShow PClass="error-message" PText={phoneError} />}
                </div>

                </div>
                
                <div className="form-row">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-textbox"
                    
                  />
                  <label htmlFor="form-email" className="form-label">
                    E-mail
                  </label>
                  {emailError && !email && <ErrorTextShow PClass="error-message" PText={emailError} />}
                </div>
                {/* <div class="form-row">
                <div class="select">
                <select class="select-text form-textbox" required >
                <option value="" disabled selected></option>
                <option value="1">Services1</option>
                <option value="2">Services2</option>
                <option value="3">Services3</option>
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label">Services you are looking for</label>
                </div>
                </div>              */}
                <div className="form-row">
                <div class="form__group">
                
                <textarea
                  id="message"
                  value={message}
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  className="form__field"
                  placeholder="Share your app vision with us"
                  rows="6"
                ></textarea>
                <label htmlFor="message" className="form__label">
                  Share Your App Vision With Us
                </label>
                {messageError && !message && <ErrorTextShow PClass="error-message" PText={messageError} />}
                </div>
                </div>
                <div className="captcha-d align-items-center">
               
                <div className="form-row">
               <div className='d-flex align-items-center gap-2'>
               <div id="CAPTCHA"><span>{captcha}</span></div>
               <button type='button' className="reload" onClick={handleReload}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
              </svg></button>
              </div>
              </div>
              <div className="form-row">
              
               <div class="form__group">
                   
                     <input
                      type="text"
                      value={captchaInput}
                      onChange={handleInputChange}
                      className="form-textbox"
                      placeholder=""
                    />
                    <label htmlFor="form-phone" className="form-label">
                      Type CAPTCHA Here
                    </label>
                    
                    {captchaError && <ErrorTextShow PClass="error-message" PText={captchaError} />}
             
                    
                    {/* {errorMessage && <div>{errorMessage}</div>}  */}
                    {/* {successMessage && <div>{successMessage}</div>} */}                    
                    {/* <button onClick={handleBack}>Back</button> */}
                </div>
                </div>
                  
                </div>
                <div class="form-row updt">
                  <input type="submit" value="SEND MESSAGE &rarr;" className="form-submit" />
                  <div className="lodimg">
                    {tableLoader ? (<img className='loa_der' src={CircularProgress}/>) : ""}
                    {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
                  </div>
                  
                </div>
              </form>
            </div>
          </div> 



    </div>
      
   
  <div className="contact_frm_img">
  <img src={Forms} alt="Contact" />
     
      
  </div>
  </div>
  </Container>
</section>
      
      
      <Footer></Footer>
  
    </main>
  )
}

function ErrorTextShow({ PClass, PText }) {
  return <span style={{ color: 'red' }} className={PClass}>
    {PText}
  </span>;
}
