import React, { useState, useRef, useEffect } from "react";
import Slide1 from "./slide1";
import Slide2 from "./slide2";
import Slide3 from "./slide3";
import Slide4 from "./slide4";
import Slide5 from "./slide5";
import Slide6 from "./slide6";
import Slide7 from "./slide7";
import Slide8 from "./slide8";
import Slide9 from "./slide9";
import Slider from "react-slick";

import Thumb1 from '../Assest/Images/ask-a-vet.png';
import Thumb2 from '../Assest/Images/augusta1.png';
import Thumb3 from '../Assest/Images/Deliver-x.png';
import Thumb4 from '../Assest/Images/isnd.png';
import Thumb5 from '../Assest/Images/metafit-io.png';
import Thumb6 from '../Assest/Images/thuis-bezurgad.png';
import Thumb7 from '../Assest/Images/TLC.png';
import Thumb8 from '../Assest/Images/yogavivo.png';
import Thumb9 from '../Assest/Images/zk-wallet.png';

const Portfolio = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    adaptiveHeight: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div>
      
      <Slider className="thumb_slider"
      {...settings}
        asNavFor={nav1}
        ref={slider2}
        // slidesToShow={8}
        swipeToSlide={true}
        focusOnSelect={true}
      >
        <div className="thumb">
        <img src={Thumb1} alt="thumb1" />
        </div>
        <div className="thumb">
        <img src={Thumb2} alt="Thumb2" />
        </div>
        <div className="thumb">
        <img src={Thumb3} alt="Thumb3" />
        </div>
        <div className="thumb">
        <img src={Thumb4} alt="Thumb4" />
        </div>
        <div className="thumb">
        <img src={Thumb5} alt="Thumb5" />
        </div>
        <div className="thumb">
        <img src={Thumb6} alt="Thumb6" />
        </div>
        <div className="thumb">
        <img src={Thumb7} alt="Thumb7" />
        </div>
        <div className="thumb">
        <img src={Thumb8} alt="Thumb8" />
        </div>
        <div className="thumb">
        <img src={Thumb9} alt="Thumb9"/>
        </div>
      </Slider>

      <Slider {...settings2} className="big_slider" asNavFor={nav2} ref={slider1}>
        <div>
            {Slide1()}         
        </div>
        <div>
            {Slide2()}
        </div>
        <div>
            {Slide3()}
        </div>
        <div>
            {Slide4()}
        </div>
        <div>
            {Slide5()}
        </div>
        <div>
            {Slide6()}
        </div>
        <div>
            {Slide7()}
        </div>
        <div>
            {Slide8()}
        </div>
        <div>
            {Slide9()}
        </div>
      </Slider>
      
      
    </div>
  );
};

export default Portfolio;
