import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
// import ANDR from "../Assest/Images/ANDORID1.png";
import IOS from "../Assest/Images/IOS1.png";

import Slide1 from "../Assest/Images/Augusta/1.jpg";
import Slide2 from "../Assest/Images/Augusta/2.jpg";
import Slide3 from "../Assest/Images/Augusta/3.jpg";
import Slide4 from "../Assest/Images/Augusta/4.jpg";
import Slide5 from "../Assest/Images/Augusta/5.jpg";


const slide2 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link
          to={"/digital-wellbeing-and-mental-health-app-development-company"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="digital-wellbeing-and-mental-health-app-development" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="digital-wellbeing-and-mental-health-app-development" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="digital-wellbeing-and-mental-health-app-development" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="digital-wellbeing-and-mental-health-app-development" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="digital-wellbeing-and-mental-health-app-development" src={Slide5} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                7<span>Months</span>
              </h4>
              <h3>AUGUSTA</h3>
              <h5>React Native</h5>
              <h1>Built with technology look up</h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Frontend</h4>
                  <ul>
                    <li>React Native</li>
                    <li>JavaScript/TypeScript</li>
                    <li>Axios</li>
                    <li>Background UI Threa</li>
                    <li>AES Encryption</li>
                    <li>HMAC Authentication</li>
                  </ul>
                </div>

                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>PHP CodeIgniter</li>
                    <li>MySQL</li>
                    <li>AWS Cloud Services</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span>
                      <a href="https://apps.apple.com/in/app/augusta/id1638868796">
                        <img src={IOS} alt="IOS" />
                      </a>
                    </span>
                  </div>
                </div>
                <div></div>
                <div className="col_g">
                  {/* <Link
                    to={
                      "/digital-wellbeing-and-mental-health-app-development-company"
                    }
                    className="showMore mt-0"
                    
                  >
                    Read the full case study
                  </Link> */}

                  <a
                  href="/digital-wellbeing-and-mental-health-app-development-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide2;
