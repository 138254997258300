import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 

import ButtonContact from '../Component/ButtonContact';

import "./home.css";
import "./subpages.css";
import TLCimg from "../Assest/Images/tlc_banner2.png"
import Ideaimg from "../Assest/Images/idea.png";
import Solution from "../Assest/Images/solution-tlc.png";

import ReactIcon from "../Assest/Images/reactnativeicon.png";
import MIcon from "../Assest/Images/micon.png";
import back4appIcon from "../Assest/Images/back4app-icon.png";

import TLClogo from "../Assest/Images/TLClogo.png";
import googlePlay from "../Assest/Images/googleplay.png";


import { useEffect } from "react";
import { Container } from "react-bootstrap";




export function Thelovechain(props) {
  useEffect(() => {
    AOS.init();
  }, [])
  React.useEffect(() => {
    document.title = 'Decentralized Social Media Dapps Development Company';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Looking for a Web3 social media dapp development company? Explore TheLoveChain app by Immanent Solutions—transform memories into NFTs. Contact us for Web3 Social Media DApps Clones');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Web 3 social media DApp, NFTs Integrated into Web 3.0 Social Platforms, Social Media Tokenization, Web 3.0 Social Media Platforms Development, Social Media NFT Platform Development');
  
    // Check if canonical link already exists
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.setAttribute('href', 'https://immanentmobile.dev/decentralized-social-media-apps-development-company');
  
  
  }, []);


  return (  
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner justify-content-center">
    <div className="innerpagebanner_img img-top-0 text-center">
        <img
        // className="up-down"
        alt="The Love Chain" src={TLCimg} className="mx-auto mx-w-100" />
         <div className="app-store-icons bot-0 right-0 left-0">
                  <a href="https://play.google.com/store/apps/details?id=com.love.chain" target="_blank"><img src={googlePlay} alt="Thelovechain"/></a>
                 
              </div>
      </div>
   
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-purple-light  m-t-m350" 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack mt-6" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading text-purple sentensecase">What is<br />    
TheLoveChain ?</h1>
      <p className="para-grey">The LoveChain allows users to create their favourite content and share it with the world. 
It also enables the users to store their most important memories on the blockchain and 
enables NFT minting within the app.
</p>
    </Container>
  </div>
</section>



<section className="project-idea mt-0" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex  project-pad">
        <div className="col-lg-6 proj-block-small purple"><img src={Ideaimg} alt="Project Idea" className="img-fluid"/> </div>
        <div className="col-lg-6 pt-5 mt-3 pt-sm-0">
          <h1 className="subpage-heading heading-box-end head-box-purple"> Project Idea</h1>
           <p className="para-grey">
           TheLoveChain revolutionizes content creation 
by combining Instagram-like features for audio, 
video, and text with Algorand blockchain's 
secure storage of precious memories. Users 
can effortlessly mint NFTs within the app, 
adding a new dimension to their creative 
expression and digital ownership.
           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-12 pt-5 text-center">
         <h1 className="subpage-heading heading-box-end head-box-purple">Challenges</h1>
          <p className="para-grey text-center">
          Navigating the Expo framework presented an initial challenge, requiring a dedicated 
learning curve. Integrating with Back4App's distinct backend architecture demanded 
in-depth understanding and custom development. Implementing the Algorand SDK 
for NFT minting presented a complex technical challenge involving blockchain 
technology and unfamiliar APIs.

          </p>
        </div>
        
      </div>
      <div className="row d-flex align-items-center  project-pad">
        <div className="col-lg-6 ">
       <h1 className="subpage-heading heading-box-end head-box-purple">Solutions</h1>
        <p className="para-grey">
        Mastering the Expo framework, we dedicated 
focused time to understand its intricacies, 
enabling efficient mobile app development. 
Additionally, we seamlessly integrated with 
Back4App's backend, employing a customized 
approach for smooth communication and data 
persistence. Furthermore, we demonstrated 
proficiency in blockchain technology by 
successfully implementing the Algorand SDK, 
showcasing expertise in NFT minting.
          </p>
        </div>
        <div  className="col-lg-6 text-end">
          <img src={Solution} alt="Solution" className="img-fluid"/>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="core-features bg-purple-light" id="Features">
  <div>
    <Container>
      <div className="row ">
        <div className="col-lg-11 px-4 mx-auto">
          <div className="core-features-inner py-5 bg-purple-light">
          <div className=" w-auto d-flex  p-3">
              <div className="core-feature-left">
             <h1 className="subpage-heading heading-box-end head-box-white">Main features</h1>
                <p className="para-grey">
                <ul className="circle-list">
               <li>Instagram-type feed management</li>
               <li>Comment, share, like options
               </li>
                <li>AlgoRand blockchain integration for:
                </li>
                    <ul >
                        <li>Wallet deployment</li>
                        <li>NFT minting process</li>
                    </ul>
                <li>Save post</li>
                <li>Deploy feed post as NFT on Algorand blockchain
                </li>
                <li>Follow and following concept</li>
                <li>Save posts in profile</li>
                <li>Category management for feed posts</li>
                <li>Profile management</li>
               
                
                </ul>
                </p>
              </div>
          
               

               
            </div>
          </div>
           
        </div>
      </div>
    </Container>
  </div>
</section>


<section className="app-services-block" id="Services">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center">
      <div className="col-lg-12 text-center">
       <h1 className="subpage-heading heading-box-end head-box-purple ">Services we provide &nbsp;</h1>
        <div className="inner_contac ">
        <div className="inner_contact">
          <div className="inner_subappservice align-items-center">
            <div className="col-app">
                <div className="appservice_grd bg-violet">
                <div className="grd_txt">
                    <p>Concept <br />Brainstorming</p>
                </div>
                </div>
                <div className="appservice_grd bg-purple-light">
                <div className="grd_txt">
                <p>Development </p>
                </div>
                </div>
            </div>
          
           
            <div className="col-app ">
                <div className="appservice_grd text-white bg-purple">
                <div className="grd_txt">
                <p>Wireframing and 
    <br />Architectural Design</p>
                </div>
                </div>
                <div className="appservice_grd  bg-purple-light">
                <div className="grd_txt">
                <p>App store & Play Store 
    <br />publication assistance</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-purple">
                <div className="grd_txt">
                <p>Support and 
    <br />maintenance</p>
                </div>
                </div>
            </div>
            <div className="col-app order-xl-3">
                <div className="appservice_grd bg-purple-light">
                <div className="grd_txt">
                <p> UI/UX 
    <br />graphics</p>
                </div>
                </div>
                <div className="appservice_grd bg-violet">
                <div className="grd_txt">
                <p>Testing and 
                <br />Review</p>
                </div>
                </div>
            </div>
           
        
           
           
          </div>
        </div>
      </div>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="tech-stack-block bg-purple-light" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-purple">Tech Stack</h1>
          <div className="tech-stack-icons">
            <img src={ReactIcon} alt="icone" className="img-fluid" />
            <img src={MIcon} alt="iccon" className="img-fluid"/>
            <img src={back4appIcon} alt="icon" className="img-fluid" />
            
          </div>
          </div>
         </div>
      </div>
   
    </Container>
  
  </div>
  
</section>

<section className="delivery-dx bg-purple mb-4 mt-4 py-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-7"> 
          <div className="delivery-text">
          <h3>Start your Social NFT Platform: <br />
Bring your idea to life now.!</h3>
              <div className="mt-4">
              <ButtonContact />
              </div>
          </div>
            
          </div>
          <div className="col-lg-5 text-end">
              <img src={TLClogo} alt="TLC LOGO" className="img-fluid "/>
          </div>
      </div>
  </Container>
</section>



      
      <Footer></Footer>
  
    </main>
  )
}
