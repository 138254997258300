import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
// import ANDR from "../Assest/Images/ANDORID1.png";
import IOS from "../Assest/Images/IOS1.png";

import Slide1 from "../Assest/Images/ask-a-vet/on-boarding-1.jpg";
import Slide2 from "../Assest/Images/ask-a-vet/on-boarding-2.jpg";
import Slide3 from "../Assest/Images/ask-a-vet/on-boarding-3.jpg";
import Slide4 from "../Assest/Images/ask-a-vet/login.jpg";
import Slide5 from "../Assest/Images/ask-a-vet/home.jpg";
import Slide6 from "../Assest/Images/ask-a-vet/clients.jpg";
import Slide7 from "../Assest/Images/ask-a-vet/client-profile.jpg";
import Slide8 from "../Assest/Images/ask-a-vet/pet-documents.jpg";
import Slide9 from "../Assest/Images/ask-a-vet/pet-profile.jpg";
// import { Link } from "react-router-dom";

const slide1 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link 
          to={"/petcare-and-veterinary-consultation-app-development-company"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide5} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide6} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide7} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide8} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="petcare-and-veterinary" src={Slide9} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                6<span>Months</span>
              </h4>
              <h3>ASK A VET </h3>
              <h5>React Native</h5>

              <h1>Built with technology look up</h1>

              <div className="main_grds">
                <div className="col_g">
                  <h4>Mobile App</h4>
                  <ul>
                    <li>React Native</li>
                    <li>React Native Widgets and Components</li>
                    <li>Redux State Management</li>
                    <li>Google Place and Address API</li>
                    <li>Firebase Real-Time Chat</li>
                    <li>FCM Push Notifications</li>
                    <li>RevenueCat for In-App Purchases</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>NodeJS</li>
                    <li>MongoDB</li>
                    <li>FCM for Push Notifications</li>
                    <li>AWS S3 for File Storage </li>
                  </ul>
                </div>

                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span className="d-v-grid">
                      <span>Pet App</span>
                      <a href="https://apps.apple.com/in/app/ask-a-vet/id6451296634">
                        {" "}
                        <img src={IOS} alt="IOS" />
                      </a>
                    </span>
                    <span className="d-v-grid">
                      <span>Vet App</span>
                      <a href="https://apps.apple.com/in/app/vetdoctor/id6451299868">
                        <img src={IOS} alt="IOS" />
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col_g"></div>
                <div className="col_g">
                {/* <Link  to="/petcare-and-veterinary-consultation-app-development-company" className="showMore mt-0">
                  Read the full case study
                </Link> */}

                <a
                  href="/petcare-and-veterinary-consultation-app-development-company"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                  
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide1;
