import React from 'react';
// import TestiMonialsDetails from './testimonialSlider'

import Testimon from '../Assest/Images/testimon.png';
import Kerry from '../Assest/Images/kerry.png';
import Sahir from '../Assest/Images/sahir.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import './testimonal.css';


import userPic from '.././Assest/Images/ANDORID.png';
// const testiMonials = [
//     {
//         name: 'Kerry Regan',
//         description: " It's been my pleasure to work with Immanent Solutions for 10 years. My business in Australia has been greatly enhanced with their knowledge and experience as we have worked closely on many complicated projects.",
//         address: 'DataSafe Apps',
//         img: Kerry
//     },
//   {
//       name: 'Sahir N',
//       description: "It's been an absolute pleasure working with the team at Immanent Solutions. Their versatility in handling various tech projects is truly exceptional. Immanent Solutions The team's communication skills are top-notch; they ensure everyone is on the same page, fostering smooth collaboration. What sets them apart is their consistent delivery on time and within budget, a testament to their reliability and professionalism. Moreover, their ability to think outside the box for innovative solutions adds immense value to every project. I highly recommend Immanent Solutions for any tech endeavor; they're a reliable partner committed to excellence."
//       ,
//       address: ' ',
//       img: Sahir
//   },
//   {
//       name: 'Zug',
//       description: "Quality, on-time and great communication and well delivered to specification! Thanks",
//       address: 'Entrepreneur and CEO - LogicX Solutions',
//       img: Testimon
//   },
//   {
//       name: 'Thomas Hamrick',
//       description: "Really Immanent is a genius Team. And I absolutely am honoured to work with them. Super smart, and hardworking, never unfair. Highly recommended - seriously!" ,
//       address: 'Entrepreneur and CEO - LogicX Solutions',
//       img: Testimon
//   },
// ]
// const options = {
//   loop: true,
//   center: true,
//   items: 3,
//   margin: 0,
//   autoplay: true,
//   dots: true,
//   autoplayTimeout: 8500,
//   smartSpeed: 450,
//   nav: false,
//   responsive: {
//       300: {
//           items: 1
//       },
//       600: {
//           items: 2
//       },
//       1000: {
//           items: 3
//       }
//   }
// };
export function Testimonials (props) { 
  return (
     <>
<div class="owl-carousel owl-theme" id="customer-testimonoals" 
 >
                          
                                    <div class="item">
                                        <div className='testimonial_content shadow-effect '>
                                        <div class="card-body" >    
                                            <p> It's been my pleasure to work with Immanent Solutions for 10 years. My business in Australia has been greatly enhanced with their knowledge and experience as we have worked closely on many complicated projects.</p>
                                        </div>
                                        <div class="testimonial_profile">
                                        <img  src={Kerry} /> <h2>Kerry Regan<span>DataSafe Apps</span></h2>
        </div>
        <h3>01</h3>
                                        </div>
                                     </div> 
                                     <div class="item">
                                        <div className='testimonial_content shadow-effect '>
                                        <div class="card-body" >    
                                            <p> It's been an absolute pleasure working with the team at Immanent Solutions. Their versatility in handling various tech projects is truly exceptional. Immanent Solutions The team's communication skills are top-notch; they ensure everyone is on the same page, fostering smooth collaboration. What sets them apart is their consistent delivery on time and within budget, a testament to their reliability and professionalism. Moreover, their ability to think outside the box for innovative solutions adds immense value to every project. I highly recommend Immanent Solutions for any tech endeavor; they're a reliable partner committed to excellence.</p>
                                        </div>
                                        <div class="testimonial_profile">
                                        <img  src={Sahir} /> <h2>Sahir N<span> </span></h2>
        </div>
        <h3>02</h3>
                                        </div>
                                     </div>
                                     <div class="item">
                                        <div className='testimonial_content shadow-effect'>
                                        <div class="card-body" >    
                                            <p> Quality, on-time and great communication and well delivered to specification! Thanks</p>
                                        </div>
                                        <div class="testimonial_profile">
                                        <img  src={Testimon} /> <h2>Zug<span>Entrepreneur and CEO - LogicX Solutions</span></h2>
        </div>
        <h3>03</h3>
                                        </div>
                                     </div>
                                     <div class="item">
                                        <div className='testimonial_content shadow-effect'>
                                        <div class="card-body" >    
                                            <p>Really Immanent is a genius Team. And I absolutely am honoured to work with them. Super smart, and hardworking, never unfair. Highly recommended - seriously!" ,
</p>
                                        </div>
                                        <div class="testimonial_profile">
                                        <img  src={Testimon} /> <h2>Thomas Hamrick<span>Entrepreneur and CEO - LogicX Solutions</span></h2>
        </div>
        <h3>04</h3>
                                        </div>
                                     </div>
                                    
                                  {/*  <div className='item'>
      <div className='testimonial_content'>
      <p> {description}</p>
        <div className='testimonial_profile'>
        <img src={img} /><h1>{name}<span>{address}</span></h1>
        </div>
        <h3>02</h3>
        </div>
        </div> */}
                        </div>



                        </>

    
  );
};

export default Testimonials;