import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 

import "./home.css";
import ButtonContact from '../Component/ButtonContact';
import Augustaimg from "../Assest/Images/Augusta_banner.png";

import Ideaimg from "../Assest/Images/idea.png";
import Solution from "../Assest/Images/solution-augusta.png";


import ZKIcon from "../Assest/Images/augusta_logo.png";
import ReactIcon from "../Assest/Images/reactnativeicon.png";
import PhpIcon from "../Assest/Images/phpIcon.png";
import CodeigniterIcon from "../Assest/Images/codeigniter.png";
import MysqlIcon from "../Assest/Images/mysql.png";
import FirebaseIcon from "../Assest/Images/firebase.png";
import SendgridIcon from "../Assest/Images/sendgrid.png";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
// import googlePlay from "../Assest/Images/googleplay.png";
import appleStore from "../Assest/Images/appstore.png";



export function Augusta(props) {
  useEffect(() => {
    AOS.init();
  }, [])

  React.useEffect(() => {
    document.title = 'Digital Wellbeing & Mental Health App Development Company';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Build your digital wellness & mental health app with screen time management, activity tracking, and social features. Explore Augusta App by Immanent Solutions.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Digital Wellbeing App, Mental Health App, Augusta App, Screen Time Management, Activity Tracking, Social Engagement, Wellness Application, Screen Management App, Wellbeing Application');
  
    // Check if canonical link already exists
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://immanentmobile.dev/digital-wellbeing-and-mental-health-app-development-company');
  
  }, []);


  return (  
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner justify-content-between">
    <div className="innerpagebanner_img all-take-w col-lg-7 pt-4">
        <img
        // className="up-down"
        alt="Augusta" src={Augustaimg} className="mx-w-100 me-1" />
      </div>
      <div className="banner_text orange-banner-bg augusta-b m-s0 bg-crocus text-white px-1 col-lg-5"  data-aos="zoom-out" data-aos-delay="400">
        <div>
        <img src={ZKIcon} alt="ZK Crypto" className="img-fluid" />
       
        <p className="text-start px-3">Developed a mobile application,
Augusta, focused on promoting user 
well-being and mental health by 
reducing excessive mobile screen 
time. The application tracks user 
activity, encourages social 
engagement, and provides insights 
to manage screen usage effectively.</p>
        </div>
        <div className="app-store-icons app-relative-icons">
                  <a href="https://apps.apple.com/in/app/augusta/id1638868796" target="_blank"><img src={appleStore} alt="Augusta"/></a>
                
              </div>
      </div>
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-augusta-light " 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading sentensecase">What is Augusta?
 
</h1>
      <p className="para-grey ">Augusta is a mobile application designed to promote user well-being and mental health 
by addressing the issue of excessive mobile screen time. The app focuses on tracking 
user activity, encouraging social engagement, and providing insights to help users 
manage their screen usage effectively.
</p>
    </Container>
  </div>
</section>



<section className="project-idea" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-6 proj-block-small crocus"><img src={Ideaimg} alt="Project Idea" className="img-fluid"/> </div>
        <div className="col-lg-6 pt-5 pt-sm-0">
          <h1 className="subpage-heading heading-box-end head-box-augusta"> Project Idea</h1>
           <p className="para-grey">
           A mobile app combating excessive screen 
time, fostering well-being through features 
like screen tracking, social engagement, and 
actionable insights. The goal is to empower 
users for effective screen management, 
emphasizing positive behav
           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-12 pt-5 text-center">
         <h1 className="subpage-heading heading-box-end head-box-augusta ">Challenges </h1>
          <p className="para-grey text-center">
          The primary challenge in developing Augusta was implementing a seamless background
screen recording feature, requiring the creation of a dedicated controller, efficient data 
synchronization, and robust fraud prevention mechanisms.

          </p>
        </div>
        
      </div>
      <div className="row d-flex  project-pad">
        <div className="col-lg-6 align-self-end">
       <h1 className="subpage-heading heading-box-end head-box-augusta">Solutions</h1>
        <p className="para-grey">
        To address these challenges, a specialized background 
controller was designed for unobtrusive data acquisition. 
Efficient data synchronization ensured accurate backend 
updates, while robust fraud prevention mechanisms were 
implemented to maintain the integrity of the recorded 
screen time data


          </p>
        </div>
        <div  className="col-lg-6 align-self-end text-end">
          <img src={Solution} alt="Solution" className="img-fluid"/>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="core-features bg-augusta-light" id="Features">
  <div>
    <Container>
      <div className="row ">
        <div className="col-lg-11 px-4 mx-auto">
          <div className="core-features-inner bg-augusta-light">
          <div className=" w-auto d-flex  p-3">
              <div className="core-feature-left">
             <h1 className="subpage-heading heading-box-end head-box-augustaW">Main features</h1>
                <p className="para-grey">
                <ul className="circle-list">
                <li> User screentime management</li>
                <li>Real-time push notification for screentime usage</li>
                <li>1 hour of application use time in a day</li>
                <li>Social feed management</li>
                <li>Comments, likes, share</li>
                <li>Profile management</li>
                <li>Follow and following</li>
                <li>Notifications management</li>             
                
                </ul>
                </p>
              </div>
          
               

               
            </div>
          </div>
           
        </div>
      </div>
    </Container>
  </div>
</section>


<section className="app-services-block" id="Services">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center ">
      <div className="col-lg-12 text-center">
       <h1 className="subpage-heading heading-box-end head-box-purple ">Services we provide &nbsp;</h1>
        <div className="inner_contac ">
        <div className="inner_contact">
          <div className="inner_subappservice align-items-center">
            <div className="col-app">
                <div className="appservice_grd text-white bg-crocus">
                <div className="grd_txt">
                    <p>Concept <br />Brainstorming</p>
                </div>
                </div>
                <div className="appservice_grd bg-purple-light">
                <div className="grd_txt">
                <p>Development </p>
                </div>
                </div>
            </div>
          
           
            <div className="col-app ">
                <div className="appservice_grd text-white bg-crocus">
                <div className="grd_txt">
                <p>Wireframing and 
    <br />Architectural Design</p>
                </div>
                </div>
                <div className="appservice_grd  bg-purple-light">
                <div className="grd_txt">
                <p>App store & Play Store 
    <br />publication assistance</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-crocus">
                <div className="grd_txt">
                <p>Support and 
    <br />maintenance</p>
                </div>
                </div>
            </div>
            <div className="col-app order-xl-3">
                <div className="appservice_grd bg-purple-light">
                <div className="grd_txt">
                <p> UI/UX 
    <br />graphics</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-crocus">
                <div className="grd_txt">
                <p>Testing and 
                <br />Review</p>
                </div>
                </div>
            </div>
           
        
           
           
          </div>
        </div>
      </div>
        </div>
      </div>
    </Container>
  </div>
</section>



<section className="tech-stack-block bg-augusta-light" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-augusta">Tech Stack</h1>
          <div className="tech-stack-icons">
            <img src={ReactIcon} alt="icone" />
            <img src={PhpIcon} alt="iccon" />
            <img src={CodeigniterIcon} alt="icon" />
            <img src={MysqlIcon} alt="icon" />
            <img src={FirebaseIcon} alt="icon" />
            <img src={SendgridIcon} alt="icon" />
          </div>
          </div>
         </div>
      </div>
     
    </Container>

  </div>
  
</section>

<section className="delivery-dx bg-crocus mb-4 mt-4 py-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-10 mx-auto"> 
          <div className="delivery-text">
          <h4>Go beyond likes and follows.</h4>
          <h3>
Launch your personalized Time <br />
Management & Social App today ! .</h3>
              <div className="mt-4">
              {/* <Link className="btn btn-light contact-us-b" to={'/#Contact'}>Contact Us !</Link> */}
              <ButtonContact className="btn-light"/>
              </div>
          </div>
            
          </div>
          
      </div>
  </Container>
</section>

      
      <Footer></Footer>
  
    </main>
  )
}
