import React from 'react'
import { Header } from '../Component/Header'
import { Footer } from '../Component/Footer'
import "./notfound.css"
import pic404 from "../Assest/Images/404.png";
import text404 from "../Assest/Images/text404.png";
import { Link } from "react-router-dom";

const Notfoundpage = () => {
  return (
    <>
    <Header/>

    <section className='Page404'>
      <div className="container">
        <div className="PageErrorData">

          <div className="errorpic">
            <img src={pic404} alt="404"  />
          </div>

          <div className="errorText">
            <img src={text404} alt="text404"  />
            <h4>Oops! This page doesn’t exist</h4>
            <div className="goerror">
              <h6>Go to</h6>
              <div className="erlink">
                <Link to="/">Home</Link>
                <span>/</span>
                <Link to="/#Contact">Contact</Link>
              </div>
            </div>

          </div>




        </div>
      </div>

    </section>











    <Footer/>

    </>
  )
}

export default Notfoundpage