import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
import ANDR from "../Assest/Images/ANDORID1.png";
import IOS from "../Assest/Images/IOS1.png";
// import { Link } from "react-router-dom";

import Slide1 from "../Assest/Images/Thuis/1.png";
import Slide2 from "../Assest/Images/Thuis/2.png";
import Slide3 from "../Assest/Images/Thuis/3.png";
import Slide4 from "../Assest/Images/Thuis/4.png";
import Slide5 from "../Assest/Images/Thuis/5.png";

const slide6 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link
          to={"/food-ordering-and-delivery-app-development"}
        > */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="food-ordering-and-delivery-app-development" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="food-ordering-and-delivery-app-development" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="food-ordering-and-delivery-app-development" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="food-ordering-and-delivery-app-development" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="food-ordering-and-delivery-app-development" src={Slide5} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                2<span>Months</span>
              </h4>
              <h3>Thuisbezorgd.nl</h3>
              <h5>Native iOS and Android</h5>
              <h1>Built with technology look up </h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Android</h4>
                  <ul>
                    <li>Core Java</li>
                    <li>Android Framework</li>
                    <li>SharedPreferences</li>
                    <li>Retrofit</li>
                    <li>Realm Database</li>
                    <li>MVVM Code base Structure</li>
                    <li>Android SDK</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>IOS</h4>
                  <ul>
                    <li>Swift 4</li>
                    <li>NSUserDefault</li>
                    <li>Alamofire</li>
                    <li>Core Data</li>
                    <li>MVC Codebase</li>
                    <li>Swift UI</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>PHP with Codegniter framework</li>
                    <li>PHPMyAdmin + SQL</li>
                    <li>Plugin for Subscriptions</li>
                    <li>Plugin for subscription</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Common Technology (Android + iOS):</h4>
                  <ul>
                    <li>Google Place APIs + Maps features </li>
                    <li>FCM Push Notifications </li>
                    <li>Realm Database </li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Location-Based Services:</h4>
                  <ul>
                    <li>Google Maps </li>
                    <li>Google Geolocation API </li>
                    <li>Google Places API</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span>
                      <a href="https://apps.apple.com/in/app/thuisbezorgd-nl/id329472759">
                        <img src={IOS} alt="IOS" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.takeaway.android">
                        <img src={ANDR} alt="Android" />
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col_g">
                  {/* <Link
                    to={"/food-ordering-and-delivery-app-development"}
                    className="showMore mt-0"
                 
                  >
                    Read the full case study
                  </Link> */}
                  <a
                  href="/food-ordering-and-delivery-app-development"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide6;
