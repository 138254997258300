import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 

import "./home.css";

import Zkimg from "../Assest/Images/ZkCryptobanner.png";

import Ideaimg from "../Assest/Images/idea.png";
import Solution from "../Assest/Images/solution-zk.png";

import DriverDisplay from "../Assest/Images/serviceszk.png";
import ZKIcon from "../Assest/Images/ZkCrypto_logo.png";
import ReactIcon from "../Assest/Images/reactnativeicon.png";
import NodemsqlIcon from "../Assest/Images/nodeMySql.png";
import WalletIcon from "../Assest/Images/walletIcon.png";
import EthersIcon from "../Assest/Images/ethers.png";
import googlePlay from "../Assest/Images/googleplay.png";
import appleStore from "../Assest/Images/appstore.png";

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import ButtonContact from '../Component/ButtonContact';



export function ZKCryptocurrency(props) {
  useEffect(() => {
    AOS.init();
  }, [])

  React.useEffect(() => {
    document.title = 'Multichain Crypto Wallet App Development Company';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Explore the future of digital finance with our Multichain Crypto Wallet Development Solutions. Check out ZK cryptocurrency wallet developed by Immanent Solutions.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Multichain Crypto Wallet App Development, Crypto wallet app source code github, Ethereum based Crypto wallet, self-custody crypto wallet app, build a Crypto Wallet App Like Trust Wallet, Build a Crypto Wallet App Like Metamask Wallet, Blockchain Wallets Development, Web 3.0 Wallets Development Company');
  
    // Check if canonical link already exists
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.setAttribute('href', 'https://immanentmobile.dev/multichain-crypto-wallet-app-development-company');
  }, []);

  

  return (  

    
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner justify-content-between">
    <div className="innerpagebanner_img all-take-w ">
        <img
        // className="up-down"
        alt="ZK" src={Zkimg} className="mx-w-100" />
      </div>
      <div className="banner_text orange-banner-bg m-s0 bg-grey px-1"  data-aos="zoom-out" data-aos-delay="400">
        <div>
        <img src={ZKIcon} alt="ZK" className="img-fluid" />
       
        
        </div>
        <div className="app-store-icons">
                  <a href="https://apps.apple.com/us/app/zk-cryptocurrency-wallet/id6451382590" target="_blank"><img src={appleStore} alt="Zk Crypto"/></a>
                  <a href="https://play.google.com/store/apps/details?id=com.zkwallet" target="_blank"><img src={googlePlay} alt="Yogavivo"/></a>
        </div>
      </div>
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-black text-white" 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading text-white sentensecase">What is <br/>
      ZK Cryptocurrency Wallet?
</h1>
      <p className="para-grey text-white">ZK Wallet, an innovative mobile application, redefines the landscape of cryptocurrency 
storage, management, and transactions. Tailored for both novice and advanced users, 
ZK Wallet establishes a secure and user-friendly platform, granting full control over 
digital assets while eliminating reliance on intermediaries.
</p>
    </Container>
  </div>
</section>



<section className="project-idea" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-6 proj-block-big-g agreen"><img src={Ideaimg} alt="Project Idea" className="img-fluid"/> </div>
        <div className="col-lg-6 pt-5 pt-sm-0">
          <h1 className="subpage-heading heading-box-end head-box-agreen"> Project Idea</h1>
           <p className="para-grey">
           The core objective is to craft a user-centric 
and secure platform for cryptocurrency 
management. ZK Wallet aims to empower 
users through decentralization, support for 
various cryptocurrencies, and streamlined 
features for sending, receiving, and trading 
digital assets.
           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-12 pt-5 text-center">
         <h1 className="subpage-heading heading-box-end head-box-agreen ">Challenges </h1>
          <p className="para-grey text-center">
          Building a secure cryptocurrency wallet entails overcoming challenges such as 
cybersecurity threats to uphold user trust. Integrating with decentralized exchanges 
introduces issues related to liquidity and user experience, while educating users about 
wallet security and the complexities of blockchain technology remains challenging, 
especially for newcomers.
          </p>
        </div>
        
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-6 ">
       <h1 className="subpage-heading heading-box-end head-box-agreen">Solutions</h1>
        <p className="para-grey">
        ZK Wallet addresses security concerns through 
biometric authentication, encrypted backups, 
and hardware wallet support, ensuring user 
confidence. Continuous development, user 
feedback, and a user-friendly interface help 
navigate challenges, keeping the wallet at the 
forefront. Additionally, its user-friendly interface 
and QR code integration enhance accessibility 
for users of all levels, fostering a seamless 
and intuitive experience.


          </p>
        </div>
        <div  className="col-lg-6 text-end">
          <img src={Solution} alt="Solution" className="img-fluid"/>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="core-features bg-grey-light" id="Features">
  <div>
    <Container>
      <div className="row ">
        <div className="col-lg-11 px-4 mx-auto">
          <div className="core-features-inner bg-grey-light">
          <div className=" w-auto d-flex ps-0 p-3">
              <div className="core-feature-left">
             <h1 className="subpage-heading heading-box-end head-box-agreen ms-1">Main features &nbsp;&nbsp;</h1>
                <p className="para-grey">
                <ul className="list-circle-arrow  ms-0">
                <li>Create and Import Wallet</li>
                <li>Secure Wallet using Password Recovery Phrase</li>
                <li>Crypto Tokens Management across Testnet and Mainnet</li>
                <li>EVM-Based Multi-Chain Support</li>
                <li>Explore Crypto Assets with Historical Records</li>
                <li>Sub Wallet and External Wallet Management</li>
                <li>Wallet Connect Options for dApps Connectivity</li>
                <li>Import, Purchase, Sell NFTs using Wallet Connect on 
                dApps (Transaction approvals are handled within our 
wallet for such blockchain operations)</li>
               
                
                </ul>
                </p>
              </div>
          
               

               
            </div>
          </div>
           
        </div>
      </div>
    </Container>
  </div>
</section>






<section className="tech-stack-block bg-agreen" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-agreen">Tech Stack</h1>
          <div className="tech-stack-icons">
            <img src={ReactIcon} alt="icone" />
            <img src={NodemsqlIcon} alt="iccon" />
            <img src={WalletIcon} alt="icon" />
            <img src={EthersIcon} alt="icon" />
          </div>
          </div>
         </div>
      </div>
     
    </Container>

  </div>
  
</section>

<section className="display-app pt-0" id="Services">
      <Container>
        <div className="row project-pad"> 
           
            <div className="col-lg-6 ps-3 ">
              <img src={DriverDisplay}  alt="Driver app" className="img-fluid margin-top-20"/>
            </div>
            <div className="col-lg-6">
              <div className="heading-outside-bg heading-outside-bg-black outside-black">
             <h1 className="subpage-heading heading-inside-bg ">Services<br/>
we provide
</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 margin-top-50">
                <li>Concept Brainstorming</li>
                <li>Wireframing and Architectural</li>
                <li>Design</li>
                <li>UI/UX Graphics</li>
                <li>Development</li>
                <li>Testing and Review</li>
                <li>App Store & Play Store </li>
                <li>Publication Assistance</li>
                <li> Support and Maintenance</li>
              </ul>
             </div>
            </div>
        </div>
       
      </Container>
</section>
<section className="delivery-dx bg-agreen mt-4 py-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-12 mx-auto text-center"> 
          
          <h1>Launch your own secure and <br/>multichain crypto wallet.</h1>
         
              <div className="mt-4">
              {/* <Link className="btn btn-light contact-us-b" to={'/#Contact'}>Contact Us !</Link> */}
              <ButtonContact className="btn-light"/>
              </div>
          
            
          </div>
          
      </div>
  </Container>
</section>

 
      
      <Footer></Footer>
  
    </main>
  )
}
