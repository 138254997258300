import React from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
// import Slide1 from '../Assest/Images/first_slide.jpg';
import ANDR from "../Assest/Images/ANDORID1.png";
import IOS from "../Assest/Images/IOS1.png";

import Slide1 from "../Assest/Images/Metafit.io/1.jpg";
import Slide2 from "../Assest/Images/Metafit.io/2.jpg";
import Slide3 from "../Assest/Images/Metafit.io/3.jpg";
import Slide4 from "../Assest/Images/Metafit.io/4.jpg";
import Slide5 from "../Assest/Images/Metafit.io/5.jpg";
import Slide6 from "../Assest/Images/Metafit.io/6.jpg";
import Slide7 from "../Assest/Images/Metafit.io/7.jpg";
import Slide8 from "../Assest/Images/Metafit.io/8.jpg";
import Slide9 from "../Assest/Images/Metafit.io/9.jpg";
import Slide10 from "../Assest/Images/Metafit.io/10.jpg";
import Slide11 from "../Assest/Images/Metafit.io/11.jpg";
import Slide12 from "../Assest/Images/Metafit.io/12.jpg";
// import { Link } from "react-router-dom";
const slide5 = () => {
  return (
    <div className="port_text">
      <Container className="px-sma-0">
        {/* <Link  to={"/ai-driven-body-measurement-app-for-perfect-clothing-fits"}> */}
          <div className="inner_port_text">
            <div className="left_text">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide5} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide6} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide7} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide8} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide9} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide10} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide11} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" alt="ai-driven-body-measurement-app-for-perfect-clothing-fits" src={Slide12} />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="right_text">
              <h4 className="dated">
                1<span>Months</span>
              </h4>
              <h3>METAFIT.IO</h3>
              <h5>React Native </h5>
              <h1>Built with technology look up </h1>
              <div className="main_grds">
                <div className="col_g">
                  <h4>Mobile Platform:</h4>
                  <ul>
                    <li>React native Framework</li>
                    <li>JavaScript and TypeScript</li>
                    <li>Android </li>
                    <li>iOS</li>
                    <li>Body measurement AI model</li>
                    <li>Stripe Payment Gateway</li>
                  </ul>
                </div>

                <div className="col_g">
                  <h4>Backend</h4>
                  <ul>
                    <li>Node.JS</li>
                    <li>MongoDB</li>
                  </ul>
                </div>
                <div className="col_g">
                  <h4>Super Admins</h4>
                  <ul>
                    <li>MERN Stack</li>
                  </ul>
                </div>
                <div></div>
                <div className="col_g">
                  <h4>Platforms</h4>
                  <div className="and_ios_icon">
                    <span>
                      <img src={IOS} alt="IOS" />
                      <img src={ANDR} alt="Android" />
                    </span>
                  </div>
                </div>
                <div></div>
                <div className="col_g">
                  {/* <Link
                    to={
                      "/ai-driven-body-measurement-app-for-perfect-clothing-fits"
                    }
                    className="showMore mt-0"
                    
                  >
                    Read the full case study
                  </Link> */}
                  <a
                  href="/ai-driven-body-measurement-app-for-perfect-clothing-fits"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="showMore mt-0"
                >
                  Read the full case study
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default slide5;
