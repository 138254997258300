import { useEffect } from 'react';
import Phoneicon from "../Assest/Images/phone-icon.png";
const CalendlyButton = () => {
  useEffect(() => {
    const handleClick = () => {
      if (window.Calendly) {
        window.Calendly.initPopupWidget({ url: 'https://calendly.com/neerajkumarreactnative/30min?back=1&month=2024-03' });
      }
    };

    const button = document.getElementById('calendyButton');
    button.addEventListener('click', handleClick);

    return () => {
      button.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <button id="calendyButton" className="book_your_call"><img className="phone-icon" alt="Phone" src={Phoneicon} /> Book Your Call Today!</button>
  );
};

export default CalendlyButton;