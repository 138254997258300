import React from 'react';
// import { Link } from 'react-router-dom';
const ButtonContact = () => {
  // const scrollToContactForm = () => {
  //   const contactForm = document.getElementById('Contact');
  // if (contactForm) {
  //   contactForm.window.scrollTo(0, 0);
  // }
  // };


  return (
  //   <Link className='btn btn-light contact-us-b' to="/" onClick={scrollToContactForm}>
  //   Contact Us
  // </Link>
    <a className="btn btn-light contact-us-b" href="/#Contact">Contact Us!</a>
  );
};

export default ButtonContact;