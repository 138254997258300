import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 
import ButtonContact from '../Component/ButtonContact';
import "./home.css";

import Bannerimg from "../Assest/Images/Yogavivo_banner.png";

import Ideaimg from "../Assest/Images/idea.png";
import Solution from "../Assest/Images/solution-yoga.png";

import DriverDisplay from "../Assest/Images/main-feature-yogavivo.png";
import LogoIcon from "../Assest/Images/Yogavivo_logo.png";
import CorrjavaIcon from "../Assest/Images/corejava.png";
import VimeoIcon from "../Assest/Images/vimeo.png";
import WPhpIcon from "../Assest/Images/wordpressphp.png";
import SwiftIcon from "../Assest/Images/swift.png";
import StripeIcon from "../Assest/Images/stripe.png";
import AirplayIcon from "../Assest/Images/airplay.png";
import InappIcon from "../Assest/Images/inapp.png";
import googlePlay from "../Assest/Images/googleplay.png";
import appleStore from "../Assest/Images/appstore.png";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Hex from "../Assest/Images/hexgreen.png";
import Mphone from "../Assest/Images/mobilegreen.png";


export function Yogavivo(props) {
  useEffect(() => {
    AOS.init();
  }, [])

  React.useEffect(() => {
    document.title = 'Health & Fitness mobile app development company';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Explore Yogavivo, a fitness and yoga app by Immanent Solutions. Contact us to develop your personalized Health & Fitness app clone.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'Health & Fitness App, Yoga Meditation App, Workout and Exercise App Development, Well-being Application, Custom Fitness App, Mindfulness App');
  
  // Check if canonical link already exists
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  if (!canonicalLink) {
    canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    document.head.appendChild(canonicalLink);
  }
  canonicalLink.setAttribute('href', 'https://immanentmobile.dev/health-and-fitness-mobile-app-development-company');
  
  
  
  }, []);


  return (  
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner ">
    <div className="innerpagebanner_img all-take-w col-lg-8 pt-4 px-0 text-end">
        <img
        // className="up-down"
        alt="Banner" src={Bannerimg} className="mx-w-100 ms-4" />
      </div>
      <div className="banner_text orange-banner-bg m-s0 bg-ygreen text-white px-1 col-lg-4"  data-aos="zoom-out" data-aos-delay="400">
        <div>
        <img src={LogoIcon} alt="Logo" className="img-fluid" />
       
        </div>
        <div className="app-store-icons">
                  <a href="https://apps.apple.com/in/app/yogavivo/id1258590615" target="_blank"><img src={appleStore} alt="Yogavivo"/></a>
                  <a href="https://play.google.com/store/apps/details?id=com.yogavivo&hl=en" target="_blank"><img src={googlePlay} alt="Yogavivo"/></a>
        </div>
      </div>
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-creame-grey " 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading sentensecase">What is Yogavivo?
 
</h1>
      <p className="para-grey ">Yogavivo is a comprehensive mobile application designed to provide users with a 
holistic approach to health and well-being through yoga, meditation, and fitness 
videos. The app boasts a user-friendly interface and offers access to a rich library 
of high-quality content created by certified trainers. Users can seamlessly stream 
videos, engage in guided sessions, and enhance their overall fitness journey.

</p>
    </Container>
  </div>
</section>



<section className="project-idea" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex  project-pad">
        <div className="col-md-5 col-lg-6 proj-block-small crocus"><img src={Ideaimg} alt="Project Idea" className="img-fluid"/> </div>
        <div className="col-md-7 col-lg-6 pt-5 pt-sm-0">
          <h1 className="subpage-heading heading-box-end head-box-ygreen"> Project Idea</h1>
           <p className="para-grey">
           The project aimed to develop a mobile application 
that serves as a one-stop solution for users seeking 
convenient ccess to diverse yoga, meditation, and 
fitness content. The integration of certified trainers, 
high-quality video streaming, in-app purchases, and 
screen casting features contribute to creating a 
personalized and immersive experience for users. 
The goal is to promote a healthy lifestyle by making 
wellness practices easily accessible anytime, anywhere.
           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-12 pt-5 text-center">
         <h1 className="subpage-heading heading-box-end head-box-ygreen ">Challenges </h1>
          <p className="para-grey text-center">
          One significant challenge involved the conversion of data from the WordPress backend 
to JSON format, requiring meticulous handling of data structure disparities. Additionally, 
the implementation of in-app purchases posed a learning curve for the development 
team, especially in managing subscription updates through webhooks.

          </p>
        </div>
        
      </div>
      <div className="row d-flex project-pad">
        <div className="col-md-6 align-self-end ">
       <h1 className="subpage-heading heading-box-end head-box-ygreen">Solutions</h1>
        <p className="para-grey">
        To address data conversion challenges, a thorough 
understanding of schema differences enabled a 
seamless integration between the WordPress backend 
and the mobile app. For in-app purchases, dedicated 
efforts were invested in comprehending the workflow, 
leading to a successful integration that allowed users 
to access premium content with ease.

          </p>
        </div>
        <div  className="col-md-6 align-self-end text-end">
          <img src={Solution} alt="Solution" className="solutions-img max-height-70"/>
        </div>
      </div>
    </Container>
  </div>
</section>


<section className="display-app" id="Features">
      <Container>
        <div className="row "> 
           
            <div className="col-md-6 ps-3 text-center">
              <img src={DriverDisplay}  alt="Driver Display" className="img-fluid margin-top-20"/>
            </div>
            <div className="col-md-6">
              <div className="heading-outside-bg outside-ygreen">
             <h1 className="subpage-heading heading-inside-bg ">Main Features</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 w-lg-75 ms-0 ps-0">
                <li>Online yoga videos from 
                  experts and trainers</li>
                                  <li>Categorize and filter the 
                  videos based on interest</li>
                                  <li>In-app purchase for 
                  monthly and yearly 
                  subscriptions</li>
                                  <li>Trial or grace period access 
                  for the app</li>
                                  <li>Screencast option on the 
                  large screen to play the yoga 
                  videos</li>
                                  <li>Comment, share, and like 
                  options on the yoga videos</li>
               
              </ul>
             </div>
            </div>
        </div>
      </Container>
</section>

<section className="app-services-block" id="Services">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center">
      <div className="col-lg-12 text-center">
       <h1 className="subpage-heading heading-box-end head-box-ygreen ">Services we provide &nbsp;</h1>
        <div className="inner_contac ">
        <div className="inner_contact">
          <div className="inner_subappservice align-items-center">
            <div className="col-app">
                <div className="appservice_grd text-white bg-ygreen">
                <div className="grd_txt">
                    <p>Concept <br />Brainstorming</p>
                </div>
                </div>
                <div className="appservice_grd text-default  bg-ygreen-lt">
                <div className="grd_txt">
                <p>Development </p>
                </div>
                </div>
            </div>
          
           
            <div className="col-app ">
                <div className="appservice_grd text-white bg-ygreen">
                <div className="grd_txt">
                <p>Wireframing and 
    <br />Architectural Design</p>
                </div>
                </div>
                <div className="appservice_grd text-default  bg-ygreen-lt">
                <div className="grd_txt">
                <p>App store & Play Store 
    <br />publication assistance</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-ygreen">
                <div className="grd_txt">
                <p>Support and 
    <br />maintenance</p>
                </div>
                </div>
            </div>
            <div className="col-app order-xl-3">
                <div className="appservice_grd text-default  bg-ygreen-lt">
                <div className="grd_txt">
                <p> UI/UX 
    <br />graphics</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-ygreen">
                <div className="grd_txt">
                <p>Testing and 
                <br />Review</p>
                </div>
                </div>
            </div>
           
        
           
           
          </div>
        </div>
      </div>
        </div>
      </div>
    </Container>
  </div>
</section>



<section className="tech-stack-block bg-ygreen-lt" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-ygreen">Tech Stack</h1>
          <div className="tech-stack-icons">
            <img src={CorrjavaIcon} alt="icone" />
            <img src={SwiftIcon} alt="iccon" />
            <img src={VimeoIcon} alt="icon" />
            <img src={StripeIcon} alt="icon" />
            <img src={AirplayIcon} alt="icon" />
            <img src={InappIcon} alt="icon" />
            <img src={WPhpIcon} alt="icon" />
          </div>
          </div>
         </div>
      </div>
     
    </Container>
    <div className="rating-app2">
                
                <div className="hr-border-label2 border-color-ygreen"> </div>
                <div className="rating-wrap"> 
                <Container className="d-grid h-100">
                  <div className="row">
                    <div className="col-md-4 xs-hide phone-left">
                    <img src={Mphone} alt="MPhone" />
                    </div>
                      <div className="col-sm-12 col-md-11 col-lg-8 position-relative mx-auto">
                        <div className="labels-rating2  ">
                            <div className="rating-1">
                                <img src={Hex} alt="hex" className="" />
                            </div>
                            <div className="rating-2">
                                <img src={Hex} alt="hex" />
                            </div>
                          <div className="rating-1-content rating-1-content-yg">
                                <div className="circle-rate border-color-ygreen">
                                    3+
                                </div>
                                <div className="label-text">
                                  Rating
                                </div>
                          </div>
                          <div className="rating-2-content rating-2-content-yg">
                              
                                <div className="label-text">
                                  Downloads
                                </div>
                                <div className="circle-rate-2 bg-ygreen">
                                    5K+
                                </div>
                          </div>
                        </div>
                       
                      </div>
                  </div>
                </Container> 
                </div>
                               
                
  </div>
  </div>
  
</section>

<section className="delivery-dx bg-ygreen mb-4 mt-4 py-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-10 text-center mx-auto"> 
          <div className="delivery-text">
         
          <h3>
          Build your ultimate Yoga App Today!</h3>
              <div className="mt-4">
              <ButtonContact />
              </div>
          </div>
            
          </div>
          
      </div>
  </Container>
</section>

      
      <Footer></Footer>
  
    </main>
  )
}
