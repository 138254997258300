import React from "react";
import "./Blogpage.css";
import { Header } from "../../Component/Header";
import { Footer } from "../../Component/Footer";
import Feture from "../../Assest/Images/Blogs/blogbg.png";
import blg1 from "../../Assest/Images/Blogs/blogsbgg.png";
import blg2 from "../../Assest/Images/Blogs/blogsbgg2.png";
import ButtonContact from "../../Component/ButtonContact";
import Ballon from "../../Assest/Images/ballon.png"
import soontext from "../../Assest/Images/cominGtext.png"

const BlogPage = () => {
  return (
    <>
      <Header />

  <section className="CommingSoon">
  <div className="container">

    <div className="SoonData">
      <div className="leftSoon">
        <img  src={Ballon} alt="Feture"  />
      </div>
      <div className="RytSoon">
        <img  src={soontext} alt="Feture"  />
        <h6>We are currently working <br />
            on this page!</h6>

        <div className="soongoing">
          <span>Go To</span>
          <span className="gobtn">
            <a href="/">Home</a>
            <a href="#">/</a>
            <a href="/#Contact">Contact</a>
          </span>
        </div>    





      </div>





    </div>

  </div>
  </section>

{/* 
      <section className="BlogSection">
        <div className="Blogwrapper">
          <div className="blogHeading">
            <div class="heading_group">
              <h1 class="bar_heading_dn text-center">BLOGS</h1>
            </div>
          </div>

          <div className="blogTablist">
            
            <div className="Blogmail">
                <form action="" class="search w-form" data-hs-cf-bound="true">
                    <input class="search-input w-input" maxlength="256" name="query" placeholder="Search blog…" type="search" id="search" required=""/>
                    <input type="submit" class="button w-button" value="Search"/>
                </form>
            </div>
            
          </div>

          <div className="featuresDiv">
            <div className="leftFeature">
                <a  href="/blogdetails">
                    <div className="ShowFeature">
                        
                        <h4 className="headText">Gitcoin Grants 20: Results & Recap</h4>
                        <div className="fetureImg">
                            <img src={Feture} alt="Feture"  />
                        </div>

                        <div className="redbtn">
                            <a href="/blogdetails">Read</a>
                        </div>

                        
                    </div>
                </a>
            </div>
           <div className="rytfeature">
            <BLOGCARD/>
            <BLOGCARD/>
           </div>

          </div>

          <div className="BlogsProduct mt-5">

            <BlgHText bklhd="PRODUCT UPDATES"/>

            <div className="Blgprdt">
                <BLOGCARD Fimg={Feture}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/65ddfc65335bc1301f16a879_Allo%20Mainnet%20Blog%20Banner%202.jpg"}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/654da7becaf0c4ea94cca5d0_Copy_of_P2.3.png"}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/649058e53cf8ea11fa23447d_gsblog.png"}/>
            </div>

          </div>


          <div className="BlogsProduct mt-5">

            <BlgHText bklhd="GITCOIN GRANTS PROGRAM"/>

            <div className="Blgprdt">
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/6570b16f219d98343cb862e6_Frame_5.png"} />
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/6626e8ed104748ef2f6697d8_Screenshot%202024-04-22%20at%2015.46.57.png"} />
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/65a557eeb6b87dec188b8739_Copy%20of%20No%20pixelation%20white%20(1).png"} />
                <BLOGCARD Fimg={Feture}/>
            </div>

          </div>



          <div className="BlogsProduct mt-5">

            <BlgHText bklhd="ALL BLOGS"/>

            <div className="Blgprdt">
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/654c080e9a8002a60ec97730_Frame_427318859.png"} />
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/651dd906461bd44aeddd82a2_Frame_251.png"} />
                <BLOGCARD Fimg={Feture}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/65ddfc65335bc1301f16a879_Allo%20Mainnet%20Blog%20Banner%202.jpg"}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/654da7becaf0c4ea94cca5d0_Copy_of_P2.3.png"}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/649058e53cf8ea11fa23447d_gsblog.png"}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/6570b16f219d98343cb862e6_Frame_5.png"} />
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/6626e8ed104748ef2f6697d8_Screenshot%202024-04-22%20at%2015.46.57.png"} />
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/65a557eeb6b87dec188b8739_Copy%20of%20No%20pixelation%20white%20(1).png"} />
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/65ddfc65335bc1301f16a879_Allo%20Mainnet%20Blog%20Banner%202.jpg"}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/654da7becaf0c4ea94cca5d0_Copy_of_P2.3.png"}/>
                <BLOGCARD Fimg={"https://assets-global.website-files.com/6433e6f821ae13dd37394322/649058e53cf8ea11fa23447d_gsblog.png"}/>
                
            </div>

          </div>












        </div>
      </section>


      <div className="BlogForms">
          <div className="BlgFommDiv">
            <div className="blogImages">
              <img className="desktopview" src={blg1} alt="Feture"  />
              <img className="mobileview" src={blg2} alt="Feture"  />
            </div>
            <div className="blogcontact">
              <h2><strong>Let's build the app that transforms your Business!</strong> Get an accurate and precise cost estimation today!</h2>
              <ButtonContact />
            </div>
            
          </div>
      </div> */}

      <Footer />
    </>
  );
};

export default BlogPage;

// export function BlgHText({bklhd}) {
//     return <div className="BlgHEadings">
//         <h2>{bklhd}</h2>
//     </div>;
// }

// export function BLOGCARD({Fimg}) {
//     return <a href="/blogdetails">
//         <div className="Blog_Card">
//             <div className="blogcardImg">
//                 <img src={Fimg} alt="Feture" />
//             </div>
//             <h6 className="blgtext">Announcing: Gitcoin Grants 20</h6>
//             <div className="redbtn">
//                 <a href="/blogdetails">Read</a>
//             </div>
//         </div>
//     </a>;
// }

