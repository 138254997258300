import React, { useState } from 'react';
import mainlogo from "../Assest/Images/main_logo.png";
// import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import "./header.css"
export function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavClick = () => {
    setIsMenuOpen(false); // Close the menu when a nav-link is clicked
  };
    return (
        <>
            <header id='fixhead' className="main-headerw">
            {/* {[false, 'sm', 'md', 'lg', 'xl', 'xxl'].map((expand) => ( */}
            {[false, ].map((expand) => (
       
<Navbar bg="white py-2" expand="lg" fixed="top" expanded={isMenuOpen} onToggle={setIsMenuOpen}>
        <Container>
          <Navbar.Brand href="/"><img src={mainlogo} alt="Logo"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" onClick={handleNavClick}>
              {/* <Nav.Link href="/">Home</Nav.Link> */}
              <Nav.Link href="#Project">Project Idea</Nav.Link>
              <Nav.Link href="#Features">Features</Nav.Link>
              
              <Nav.Link href="#Services">Services</Nav.Link>
              <Nav.Link href="#techStack">Tech Stack</Nav.Link>    
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          
          </Navbar.Collapse>
        </Container>
      </Navbar>

      ))}
                
            </header>
        </>
    )
}

