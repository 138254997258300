import React from "react";
// import { bounce } from 'react-animations';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from "../Component/Headerwhite"
import { Footer } from "../Component/Footer"; 
// import { Link } from "react-router-dom";
// import {ButtonContact} from "../Component/ButtonContact";
import ButtonContact from '../Component/ButtonContact';
import "./home.css";
import "./subpages.css";
import Bannerimg from "../Assest/Images/banner-metafit.png"
import Ideaimg from "../Assest/Images/idea.png";
import Solution from "../Assest/Images/solution-metafit.png";
import Logo from "../Assest/Images/metafit-logo.png";
import ReactIcon from "../Assest/Images/reactnativeicon.png";
import HashIcon from "../Assest/Images/hash-icon.png";

import MernIcon from "../Assest/Images/mern-icon.png";
import Bodygram from "../Assest/Images/bodygram.png";
import StripeIcon from "../Assest/Images/stripe.png";
import MobileDisplay from "../Assest/Images/mobileapp.png";
import Shopapp from "../Assest/Images/shopfront.png";
import Adminapp from "../Assest/Images/superadmin.png";

import googlePlay from "../Assest/Images/googleplay.png";
import appleStore from "../Assest/Images/appstore.png";

import { useEffect } from "react";
import { Container } from "react-bootstrap";




export function Metafit(props) {
  useEffect(() => {
    AOS.init();
  }, [])

  React.useEffect(() => {
    document.title = 'AI-Driven Body Measurement App for Perfect Clothing Fits | Immanent Solutions';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Discover Metafit.io, an AI-driven body measurement app by Immanent Solutions for flawless clothing fits. Explore our social commerce merchant solution. Contact us to create your own AI platform!');
    document.querySelector('meta[name="keywords"]').setAttribute('content', 'AI-driven Body Measurement Mobile App, AI-powered Body Measurement Mobile App for Clothing, Body Measurement App, Virtual Try-on for Clothing App, Smart Body Measurement App, AI Fashion Assistant, AI-powered Body Size Recommendations App, AI app development company');
 
    // Check if canonical link already exists
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://immanentmobile.dev/ai-driven-body-measurement-app-for-perfect-clothing-fits');
 
 
 
  }, []);

  return (  
    <main>
<Header name="Immanent Solutions"></Header>
<section className="banner-sub" id="home">
  <Container>
    <div className="innerpage_banner justify-content-center">
    <div className="innerpagebanner_img pt-0 text-end ">
        <img
        // className="up-down"
        alt="Banner Image" src={Bannerimg} className="mx-w-100 center-img ms-4" />
          <div className="app-store-icons metafit-icon">
                  <a href="https://play.google.com/store/apps/" target="_blank"><img src={googlePlay} alt="Metafit"/></a>
                  <a href="https://apps.apple.com/in/app/" target="_blank"><img src={appleStore} alt="Metafit"/></a>
              </div>
      </div>
   
     
    </div>
  </Container>
</section>

<section className="what-is-section bg-red-plt " 
// style={{'display' : 'none'}}
>
  <div className="inner_Stack" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
     <h1 className="subpage-heading sentensecase">What is <br />    
Metafit.io?</h1>
      <p className="para-grey ">Metafit.io transcends the boundaries of traditional e-commerce platforms by redefining 
the way we shop for fashion. It's not just a place to buy clothes; it's your personalized 
shopping partner powered by cutting-edge AI technology. Metafit.io combines precision 
measurements, curated product selections, influencer opportunities, and a vibrant 
fashion community to create an immersive and rewarding shopping experience.
</p>
    </Container>
  </div>
</section>



<section className="project-idea" id="Project">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex  ">
        <div className="col-lg-6 proj-block-small mpurplelt"><img src={Ideaimg} alt="Project Idea" className="img-fluid"/> </div>
        <div className="col-lg-6 pt-5  mt-3 pt-sm-0">
          <h1 className="subpage-heading heading-box-end head-box-redplt"> Project Idea</h1>
           <p className="para-grey">
           The client approaches Immanent Solutions 
with their vision of closing the gap in the 
online fashion market by providing users 
with an immersive and precise virtual fitting 
experience

           </p>
        </div>
      </div>
      <div className="row d-flex align-items-center project-pad">
        <div className="col-lg-12 pt-5 text-center">
         <h1 className="subpage-heading heading-box-end head-box-redplt">Challenges Faced</h1>
          <p className="para-grey text-center">
          Online fashion shopping often poses a challenge for consumers due touncertainties 
about clothing sizes and fit, leading to increased returns and dissatisfaction. 
Existing solutions lack accuracy and personalization in sizing recommendations, 
resulting in a disconnect between virtual purchases and real-world fit.


          </p>
        </div>
        
      </div>
      <div className="row d-flex align-items-center  project-pad">
        <div className="col-lg-7 ">
       <h1 className="subpage-heading heading-box-end head-box-redplt">Solutions <br />
Implemented</h1>
        <p className="para-grey">
        Leveraging our expertise, we implemented AI Sizing 
Technology, integrating BodyGram for precise body 
measurements and eliminating size guesswork. Our 
focus on creating a seamless shopping experience 
included personalized product recommendations, 
social commerce integration for user earnings, and 
community-building features. Additionally, we offer 
end-to-end services from concept brainstorming and 
development to app store publication, making 
Metafit.io the go-to solution for modern and 
rewarding fashion commerce.

          </p>
        </div>
        <div  className=" col-lg-5 text-end">
          <img src={Solution} alt="Solution" className="img-fluid"/>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="display-app" id="Features">
      <Container>
        <div className="row">
            <div className="col-lg-12">
            <h1 className="subpage-heading text-center head-box-redplt">Main Features </h1>
            </div>
        </div>
        <div className="row mt-6"> 
            
            <div className="col-lg-4 ps-2 ms-auto">
              <img src={MobileDisplay}  alt="Mobile Display" className="img-fluid margin-top-20"/>
            </div>
            <div className="col-lg-7 order-first order-lg-last mb-4">
              <div className="heading-outside-bg outside-purplpinkgrd">
             <h1 className="subpage-heading heading-inside-bg ">Mobile App</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
                <li>Fashion E-Commerce with Feed </li>
                <li>Management</li>
                <li>Instagram-like Feeds featuring </li>
                <li>Tagged Products</li>
                <li>Referral System for Earning through </li>
                <li>Feed Promotion of Fashion Products</li>
              
              </ul>
              {/* <button className="btn btn-light view-more-b mt-4">View More</button> */}
             </div>
            </div>
        </div>
        <div className="row project-pad"> 
           
           
            <div className="col-lg-7">
              <div className="heading-outside-bg outside-purplpinkgrd heading-outside-bg2">
             <h1 className="subpage-heading heading-inside-bg">Shop Front</h1>           
              </div>
             <div>
             <ul class=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
                <li>Inventory Management</li>
                <li>Orders Management</li>
                <li>Customer Management</li>
                <li>Order Shipments Management</li>
                <li>Earnings and Wallet</li>
                <li>Dashboard Analytics and Reports</li>
                <li>Products Management and Viewing</li>
               
              </ul>
             </div>
            </div>
            <div className="col-lg-4 text-end me-auto">
              <img src={Shopapp}  alt="Shop App" className="img-fluid margin-top-20"/>
            </div>
        </div>
        <div className="row project-pad"> 
           
           <div className="col-lg-4 ps-2 ms-auto ">
             <img src={Adminapp}  alt="Admin App" className="img-fluid margin-top-20"/>
           </div>
           <div className="col-lg-7 order-first order-lg-last">
             <div className="heading-outside-bg outside-purplpinkgrd">
            <h1 className="subpage-heading heading-inside-bg ">Super Admin</h1>           
             </div>
            <div>
            <ul class=" list-circle-arrow2 l-h-e f-38 ms-0 ps-0">
               <li>User Management and Approvals for</li>
               <li>Shopfronts</li>
               <li>Dashboard Analytics with Revenue</li>
               <li>Reports</li>
               <li>Help and Support</li>
               <li>Reports and Block Users</li>
               <li>Orders and Shipments Management</li>
               <li>Inventory Management</li>
               <li>Customers and Earnings with Products</li>
               
             </ul>
            </div>
           </div>
       </div>
      </Container>
</section>


<section className="app-services-block" id="Services">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row d-flex align-items-center project-pad">
      <div className="col-lg-12 text-center">
       <h1 className="subpage-heading heading-box-end head-box-redplt ">Services we provide &nbsp;</h1>
        <div className="inner_contac ">
        <div className="inner_contact">
          <div className="inner_subappservice align-items-center">
            <div className="col-app">
                <div className="appservice_grd bg-grdpurplepink text-white">
                <div className="grd_txt">
                    <p>Concept <br />Brainstorming</p>
                </div>
                </div>
                <div className="appservice_grd text-default  bg-grdpurplepink-lt">
                <div className="grd_txt">
                <p>Development </p>
                </div>
                </div>
            </div>
          
           
            <div className="col-app ">
                <div className="appservice_grd text-white bg-grdpurplepink">
                <div className="grd_txt">
                <p>Wireframing and 
    <br />Architectural Design</p>
                </div>
                </div>
                <div className="appservice_grd text-default bg-grdpurplepink-lt">
                <div className="grd_txt">
                <p>App store & Play Store 
    <br />publication assistance</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-grdpurplepink">
                <div className="grd_txt">
                <p>Support and 
    <br />maintenance</p>
                </div>
                </div>
            </div>
            <div className="col-app order-xl-3">
                <div className="appservice_grd  bg-grdpurplepink-lt">
                <div className="grd_txt">
                <p> UI/UX 
    <br />graphics</p>
                </div>
                </div>
                <div className="appservice_grd text-white bg-grdpurplepink">
                <div className="grd_txt">
                <p>Testing and 
                <br />Review</p>
                </div>
                </div>
            </div>
           
        
           
           
          </div>
        </div>
      </div>
        </div>
      </div>
    </Container>
  </div>
</section>

<section className="tech-stack-block bg-grdpurplepink-lt" id="techStack">
  <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
    <Container>
      <div className="row  project-pad">
          <div className="col-lg-12 text-center">
          <div className="techstack-app ">
         <h1 className="subpage-heading heading-box-end head-box-redplt">Tech Stack</h1>
          <div className="tech-stack-icons justify-content-center">
            <img src={ReactIcon} alt="icone" className="img-fluid" />
            <img src={Bodygram} alt="iccon" className="img-fluid" />
            <img src={MernIcon} alt="icon" className="img-fluid" />
            <img src={HashIcon} alt="icone" className="img-fluid" />
            <img src={StripeIcon} alt="iccon" className="img-fluid"/>
           
            
          </div>
          </div>
         </div>
      </div>
   
    </Container>
    
  </div>
  
</section>

<section className="delivery-dx bg-grdpurplepink mb-4 mt-4 py-5">
  <Container>
      <div className="row d-flex align-items-center">
          <div className="col-lg-8 mx-auto "> 
          <div className="delivery-text">
          <h3>Build your Next-Gen App for  <br />
Fashion Industry with us

</h3>
              <div className="mt-4">
              {/* <ButtonContact /> */}
             <ButtonContact />
              </div>
          </div>
            
          </div>
          <div className="col-lg-4"> 
                <img src={Logo} alt="Logo" />
          </div>
         
      </div>
  </Container>
</section>



      
      <Footer></Footer>
  
    </main>
  )
}
